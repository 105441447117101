@import url(https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap);
.cart_left_content {
    display: inline-block;
    float: left;
    width: 70%;
}
span.number {
    display: inline-block;
    padding-right: 10px;
    font-weight: 300;
    font-size: 14px;
    float: left;
}
span.title {
    font-weight: 300;
    font-size: 14px;
    float: left;
    padding-left: 12px;
}
span.special-instructions {
    font-size: 15px;
    display: block;
    padding-left: 60px;
}
span.variant {
    color: #b5b5b5;
}
.cart_right_content {
    display: flex;
    width: 30%;
    text-align: right;
    flex-direction: column;
}
span.item-img img {
    width: 50px;
    height: 50px;
    border-radius: 6px;
}
.cart_right_content .price {
    font-size: 16px;
    float: right;
    font-weight: 700;
    color: #ff914d;
    font-family: 'Roboto', sans-serif;
}
h4.tilte {
    display: inline-block;
    font-size: 18px;
}
.cart_right_content.subtotal_price {
    margin-top: 5px;
}
.cart_item_section {
    border-bottom: 1px solid #e2e2e2;
    padding: 10px;
    display: flex;
}
.cart_icon svg {
    width: 38px;
    margin-right: 20px;
}
.cart_icon a {
    position: relative;
    cursor: pointer;
}
.cart_icon .cart_count {
    position: absolute;
    font-size: 11px;
    background-color: #ff914d;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    text-align: center;
    top: -10px;
    right: 10px;
    color: #fff;
    line-height: 20px;

}
.cart-doc-main {
    height: 100vh;
    background: white;
    width: 400px;
    position: fixed;
    top: 54px;
    right: 0;
    z-index: 10;
    border-left: 1px solid #e6e8ec;
    border-top: 1px solid #e6e8ec;
}
.cart-doc-main-title {
    padding: 14px 12px;
    font-size: 18px;
    background: #dfe2eb;
}
.cart-doc-main-close{
    font-size: 15px;
    font-weight: 600;
    float: right;
    cursor: pointer;
    text-decoration: underline;
    cursor: pointer;
}
.navbar-custome {
    position: fixed !important;
    width: 100%;
    top: 0;
    z-index: 10;
}
.navbar-custome .navbar-text {
    padding: 0;
}
.cart_item_remove {
    border: none;
}
.remove-cart-icon {
    width: 15px;
    float: right;
    cursor: pointer;
    line-height: 1;
    margin-left: 20px;
}
.remove-cart-icon path {
    fill: #666666 !important;
}
.bg-white {
    background-color: #ffffff;
}
.order-not-avaible-text {
    padding: 0px 5px 0px 10px;
}
/*sidebar button*/
.sidebar-button {
    bottom: 0;
    position: fixed;
    border: 1px solid #ff914d;
    /* width: 350px; */
    /* margin: 0 auto; */
    background: #ff914d;
    /* color: #ffffff!important; */
    /* display: table; */
    width: 400px;
    cursor: pointer;
    text-align: center;
    padding: 12px;
}

.sidebar-button button.btn.btn-bottom {
    font-size: 18px;
    font-weight: 600;
    font-family: 'Roboto', sans-serif;
    letter-spacing: 0.4px;
}
.sidebar-button button {
    color: #fff!important;
    text-align: center;
    box-shadow: none !important;
}
.total-amount-cart {
    bottom: 86px;
    position: fixed;
    width: 380px;
    height: 25px;
    margin-left: 10px;
    display: flex;
    justify-content: space-between;
}
.total-amount-cart span:first-child {
    font-size: 18px;
    color: #000;
    font-weight: 400;
    font-family: 'Roboto', sans-serif;
}
.total-amount-cart span:last-child {
    font-weight: 700;
    color: #ff914d;
    font-size: 18px;
    font-family: 'Roboto', sans-serif;
}
.cart-doc-main-body {
    height: calc(100% - 180px);
    /* background: red; */
    overflow-y: auto;
    /* position: relative; */
}

.sidebar-trigger.sidebar-trigger-section {
    margin-right: 400px;
}
.common-primary-link {
    text-decoration: none;
    color: #000000;
    cursor: pointer;
    font-size: 16px;
    font-weight: 700;
    font-family: 'Roboto', sans-serif;
}
.pricewrapper {
    margin-bottom: 3px;
}
span.product-quantity {
    display: block;
    font-size: 14px;
    color: #a3a3a3;
    font-weight: 500;
}
/* .common-primary-link:hover {
    text-decoration: none;
    color: #0156b3;
    cursor: pointer;
} */

.bottom-cart-wrapper {
    display: flex;
    justify-content: space-between;
    width: 100%;
    position: fixed;
    bottom: 0;
}


@media (max-width: 768px) {
    .cart-doc-main {
        width: 100%;
        border-left: none;
    }
}
@media (max-width: 767px) {
    .cart-doc-main {
        width: 100%;
        border-left: none;
    }
    .back-btn button.btn.btn-bottom {
        outline: none;
        box-shadow: none !important;
    }
    .back-btn {
        width: 35%;
        /* float: left; */
        border: 1px solid #f8f9fa;
        text-align: center;
        line-height: 60px;
        background-color: #f8f9fa;
    }
    .sidebar-button {
        width: 70%;
        position: static;
        position: initial;
        padding-top: 10px;
        padding-bottom: 10px;
    }
    .cart-doc-main-body {
        height: calc(100% - 200px);
    }
    .total-amount-cart {
        bottom: 75px;
        margin-left: 0;
    width: 100%;
    padding: 0px 20px;
    }
}

.cart-back-icon svg {
    width: 15px;
}
.swal-button:focus {
    box-shadow: none;
}
.swal-button:not([disabled]):hover {
    background-color: #f7914d;
}
.swal-button.swal-button--confirm {
    background-color: #f7914d;
}
.swal2-styled.swal2-confirm {
    background-color: #ff914d !important;
}
.swal2-styled.swal2-cancel {
    border: 1px solid #f7914d !important;
    background-color: white !important;
    color: #f7914d !important;
}
.vendor-product-container {
    max-width: 1720px;
    margin: auto;
    padding: 0 15px;
}
div#navbarText {
    width: 100%;
    display: flex;
}
.vendor-product-container .vendor-product-section .category-title {
    text-align: left;
    font-size: 24px;
    color: #000;
    font-family: 'Roboto', sans-serif;
    font-weight: 700;
    margin: 20px 0px 10px 0px;
}

.vendor-product-container .product-category-content {
    display: flex;
    width: 100%;
    border: 1px solid #ada7a7;
    margin-bottom: 30px;
    border-radius: 5px;
    padding: 10px;
    align-items: center;
}

.vendor-product-container .product-category-content .product-category-img {
    display: inline-block;
    max-width: 50%;
    padding-right: 20px;
}

.vendor-product-container .product-category-content .product-category-img .category-image {
    max-width: 100%;
    width: auto;
}

.vendor-product-container .product-category-content .product-category-text {
    display: inline-block;
    width: 50%;
}
.product-category-menu {
    display: flex;
    align-items: center;
    /* width: 100px; */
    border: 1px solid;
    /* padding: 6px 15px; */
    border-radius: 6px;
    text-align: center;
    top: 2px;
    position: relative;
    margin-right: 10px;
    border: 1px solid #ff914d;
}
.product-category-menu .prod-cate-decrease {
    float: left;
    cursor: pointer;
}

.product-category-menu .prod-cate-increase {
    float: right;
    cursor: pointer;
}

.product-category-option {
    display: inline-block;
    padding-left:15px;
}
.product-category-menu span {
    padding: 10px 23px;
    font-size: 20px;
    font-family: 'Roboto', sans-serif;
}
.product-category-menu span:nth-child(2)
{
    background: #ffe9db;
}
.dynamic-price-section span.prod-cate-decrease,.dynamic-price-section span.prod-cate-increase {
    padding: 10px 17px;
    color: #ff914d;
    font-weight: 700;
    font-family: 'Roboto', sans-serif;
}
.dynamic-price-section div:last-child p {
    border: 1px solid #d4d5d9;
    padding: 14px 19px;
    border-radius: 6px;
    margin-left: 8px;
    /* margin-bottom: -5px; */
    /* margin-top: 5px; */
    font-size: 14px;
    font-family: 'Roboto', sans-serif;
    font-weight: 700;
    color: #000;
}
.modal.show {
    padding-right: 0 !important;
    padding-left: 0 !important;
}
.product-box-model button.btn-orange.btn.btn-orange {
    padding: 10px 25px;
    font-weight: 700;
    font-size: 18px;
    outline: none;
    box-shadow: none;
}
.add-to-cart-section {
    margin-top: 15px;
}
.add-to-cart-section button {
    background-color: #ffa500;
    border: 0;
    padding: 10px 30px;
    border-radius: 35px;
    font-size: 16px;
    cursor: pointer;
    outline: 0;
    color: #fff;
}
.product-category-option select {
    font-size: 15px;
    margin-left: 10px;
    padding: 5px 10px;
}
/* .product_title {
    width: 85%;
} */
.product_title h3 {
    font-size: 18px;
    color: #000;
    font-weight: 700;
    margin: 2px 0;  
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-family: 'Roboto', sans-serif;
  }
.product-box { 
    border-radius: 15px;
    margin-bottom: 30px;
    cursor: pointer;
    display: flex;
    justify-content: flex-start;
    background-color: #fff;    
    padding: 10px;
    box-shadow:5px 8px 10px 0px rgba(0,0,0,0.2);
    font-family: 'Roboto', sans-serif;
}
.product_desc p {
    font-size: 12px;
    letter-spacing: 0.3px;
    font-weight: 400;
    color: #666666;
    margin-bottom: 0px;
    display: block;
    display: -webkit-box;   
    height: 43px;
    margin: 0 auto;   
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    line-height: normal;
}
.product_price p {
    font-size: 16px;
    font-weight: 700;
    margin-bottom: 0;
    color: #ff914d;
}

.product_modal.modal .modal-body h3.modal-product-title {
    font-size: 20px;
}
.product_modal .modal-body p {
    font-size: 14px;
    font-weight: 300;
    color: #8e8e8e;
}

.product_small_img img {
    width: 100px;
    height: 100px;
    object-fit: cover;    
    border-radius: 10px;
}
.product_details_right {
     width: 75%; 
    padding-left: 12px;
    padding-right: 5px;
}

/*header css*/
.vendor-res-img {
    width: 100%;
    height: 250px;
    background-color: #f3f3f3;
}
.vendor-res-img img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    margin: 0 auto;
    display: block;
}
.vendor-res-title h3 {
    font-size: 25px;
    color: #000000;
}
.vendor-res-desc {
    border-bottom: 1px solid #e2e2e2;
    padding: 20px;
    /* text-align: center; */
}
.vendor-res-info p {
    margin-bottom: 5px;
    font-size: 15px;
    color: #2d2d2d;
}
.spice-level select {
    width: 150px;
    height: 40px;
    border: 1px solid #ececec;
    padding-left: 8px;
    margin-left: 13px;
    outline: 0;
}
.product-addition-options {
    margin-top: 15px;
}
.product-addition-options .radio {
    width: 30%;
}
.product-addition-options .radio label {
    font-size: 13px;
    margin-bottom: 0;
}
/*radio-button css*/
.radio {
    margin: 0.5rem;
  }
  .radio input[type="radio"] {
    position: absolute;
    opacity: 0;
  }
  .radio input[type="radio"] + .radio-label:before {
    content: "";
    background: #f4f4f4;
    border-radius: 100%;
    border: 1px solid #b4b4b4;
    display: inline-block;
    width: 1.4em;
    height: 1.4em;
    position: relative;
    top: 0em;
    margin-right: 10px;
    vertical-align: top;
    cursor: pointer;
    text-align: center;
    transition: all 250ms ease;
  }
  .radio input[type="radio"]:checked + .radio-label:before {
    background-color: #ff914d;
    box-shadow: inset 0 0 0 4px #f4f4f4;
    border: 1px solid #ff914d;
  }
  .radio input[type="radio"]:focus + .radio-label:before {
    outline: none;
    border-color: #ff914d;
  }
  .radio input[type="radio"]:disabled + .radio-label:before {
    box-shadow: inset 0 0 0 4px #f4f4f4;
    border-color: #b4b4b4;
    background: #b4b4b4;
  }
  .radio input[type="radio"] + .radio-label:empty:before {
    margin-right: 0;
  }
  .product-addition-options{
    display: flex;
    flex-wrap: wrap;
    /* justify-content: space-between; */
    justify-content: flex-start;
}
.product-optional-items
{
    display: flex;
    flex-wrap: wrap;
    /* flex-direction: column; */
    /* justify-content: space-between; */
    justify-content: flex-start;
}
.product-addition-options h5,.product-optional-items h5 {
    width: 100%;
    font-weight: 700;
    font-size: 18px;
    font-family: 'Roboto', sans-serif;
    color: #000000;
}
  .product-optional-items .checkbox{
    /* width: auto; */
    width: 30%;
    margin-left: 0px;
}
.product-optional-items .checkbox label{
    font-size: 13px;
}
.radiodelivery {
    margin: 0.5rem 0px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .orderhr
  {
      margin: 5px 0px;
  }
  .radiodelivery input[type=radio] {
    position: absolute;
    opacity: 0;
    right: 15px;
  }
  .radiodelivery-label {
    font-weight: normal !important;
    width: 100%;
    position: relative;
  }
  .form-group.mb-0fg {
    margin-bottom: 0;
}
  .radiodelivery input[type=radio] + .radiodelivery-label:before {
    content: "";
    background: #ffffff;
    border-radius: 100%;
    border: 1px solid #b4b4b4;
    display: inline-block;
    width: 22px;
    height: 22px;
    position: absolute;
    top: 2px;
    right: 0;
    margin-right: 1em;
    vertical-align: top;
    cursor: pointer;
    text-align: center;
    transition: all 250ms ease;
  }
  .radiodelivery input[type=radio]:checked + .radiodelivery-label:before {
    background-color: #f7914d!important;
    box-shadow: inset 0 0 0 4px #f4f4f4;
  }
  .radiodelivery input[type=radio]:focus + .radiodelivery-label:before {
    outline: none;
    border-color: #f7914d!important;
  }
  .radiodelivery input[type=radio] + .radiodelivery-label:empty:before {
    margin-right: 0;
  }
.custome-modal-footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    border-top: 1px solid #ececec;
    padding-top: 15px;
    flex-wrap: wrap;
}
.modal-footer-main.modal-footer {
    border-top: 0;
    padding-top: 0;
}
.modal-body-main.modal-body {
    border-top: 1px solid #ececec;
    padding: 15px 0;
    width: 95%;
    margin: 0 auto;
}
.modal-header-main.modal-header {
    border-bottom: 0;
    /* padding-bottom: 0; */
}
/*checkbox design*/
.checkbox {
    display: block;
    margin-bottom: 15px;
  }
  
  .checkbox input {
    padding: 0;
    height: auto;
    height: initial;
    width: auto;
    width: initial;
    margin-bottom: 0;
    display: none;
    cursor: pointer;
  }
  
  .checkbox label,.checkbox label {
    position: relative;
    cursor: pointer;
    font-size: 15px;
  }
  
  .checkbox label:before {
    content:'';
    -webkit-appearance: none;
    background-color: transparent;
    border: 1px solid #e2e2e2;
    /* box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05), inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05); */
    padding: 10px;
    display: inline-block;
    position: relative;
    vertical-align: middle;
    cursor: pointer;
    margin-right: 4px;
  }
  
  .checkbox input:checked + label:after {
    content: '';
    display: block;
    position: absolute;
    top: 4px;
    left: 7px;
    width: 9px;
    height: 15px;
    border: solid #ff914d;
    border-width: 0 4px 4px 0;
    transform: rotate(45deg);
  }
  .checkbox input:checked + label:before {
    border: 1px solid #ff914d !important;
}
.product-special-instructions input#specialInstructions {
    height: 44px;
    border-radius: 8px;
    border: 1px solid #cecece;
}

  .product-optional-items h5 {
    margin-bottom: 15px;
}
.product-optional-items p {
    margin-bottom: 5px;
    margin-top: -15px;
    width: 100%;
    font-weight: 700;
    font-family: 'Roboto', sans-serif;
}
.product-optional-items {
    margin-top: 0px;
}
.p-t-61 {
    padding-top: 61px;
}
.special-name input {
    width: 100%;
    padding-left: 12px;
    font-size: 14px;
    height: 39px;
    outline: 0;
    border: 1px solid #ececec;
}
.special-name {
    width: 100%;
}
.model-product-image {
    /* width: 465px; */
    width: 100%;
    height: 355px;
    object-fit: cover;
    border: 1px solid #ececec;
    padding: 2px;
    border-radius: 4px;
    margin: 0 auto;
    display: block;
    margin-bottom: 10px;
}

.vendor-header .row {
    align-items: center;
}
.vendor-header {
    overflow: hidden;
    background: #f0f1f5;
}

.sidebar-cart_title-wrapper {
    display: flex;
}

.center-box-cn {
    display: table;
    height: 100vh;
    width: 100%;
}
.center-box-cn .center-box-section {
    display: table-cell;
    height: 100%;
    vertical-align: middle;
    width: 100%;
}
.product-upsell-items h5 {
    font-size: 18px;
    font-weight: 600;
}
.product_modal .modal-footer{
    justify-content: space-between !important;
}
.product-box-model h3.modal-product-title {
    font-size: 24px;
    font-weight: 700;
    color: #000;
}
.fade.product_modal.product-box-model .modal-title h5 {
    font-size: 18px;
    font-weight: 700;
    color: #ff914d;
    font-family: 'Roboto', sans-serif;
}
.product-box-model .modal-title p {
    color: #666666;
    font-size: 14px;
    font-family: 'Roboto', sans-serif;
    margin-bottom: 0;
}
.btn-orange {
    color: #fff !important;
    background-color: #ff914d !important;
    border-color: #ff914d !important;
    border: 1px solid;
}

.btn-orange:hover {
    color: #fff;
    background-color: #ff914d;
    border-color: #ff914d;
}

.btn-orange:focus {
    color: #fff !important;
    background-color: #ff914d !important;
    border-color: #ff914d !important;
    outline: 0;
}
.btn-orange:active {
    color: #fff;
    background-color: #ff914d;
    border-color: #ff914d;
}

.res-search-wrapper {
    width: 60%;
    text-align: center;
    display: inline;
    margin-left: 20px;
    position: relative;
    /* padding: 12px 0px 0px; */
}
.res-search-wrapper img {
    position: absolute;
    right: 15px;
    top: 15px;
}
.res-search-wrapper input {
    width: 100%;
    height: 50px;
    border: 1px solid #f0f1f5!important;
    padding-left: 20px;
    background: #f0f1f5;
    outline: none!important;
    box-shadow: none!important;
    border-radius: 10px;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}
input#search::-webkit-input-placeholder {
    color: #777676;
}
input#search:-ms-input-placeholder {
    color: #777676;
}
input#search::-ms-input-placeholder {
    color: #777676;
}
input#search::placeholder {
    color: #777676;
}


.delivery-content .css-g1d714-ValueContainer {
    height: 42px;
}
.delivery-content .css-1wa3eu0-placeholder {
    transform: translateY(-34%);
    box-sizing: border-box;
    top: 37%;
}
.delivery-content .css-1lovj44-Input {
    margin: -10px 2px;
}

.dynamic-price-section {
    display: flex;
    align-items: center;
    width: 50%;
}
body.modal-open {
    padding-right: 0px!important;
}

@media(max-width:1199px){
    .vendor-product-container .product-category-content .product-category-text h4 {
        font-size: 20px;
    }
    .vendor-product-section .col-md-3.catagory-col {
        flex: 0 0 33.33%;
        max-width: 33.33%;
    }
    /* .address-place {
        max-width: 25% !important;
    } */
    .sidebar-trigger-section .search-section .res-search-wrapper {
        max-width: 100% !important;
    }
    .search-section .res-search-wrapper {
        max-width: 68% !important;
    }
    .sidebar-trigger-section .address-place {
        max-width: 45%;
    }
    .sidebar-trigger-section .display-contents .order-button-group {
        width: 100% !important;
    }
    
    
    /* .display-contents .order-button-group {
        width: 45% !important;
    } */
    .restaurent-wrapper {
        padding: 10px 20px !important;
    }
}
@media(max-width:1024px){
    .vendor-product-container .product-category-content .product-category-text p{
        font-size: 14px;
    }
}

@media(max-width:991px)
{
    .sidebar-trigger-section .display-contents {
        flex-direction: column;
    }
    .sidebar-trigger-section .address-place {
        max-width: 100%!important;
    }
    .sidebar-trigger-section .search-section .res-search-wrapper {
        margin-top: 10px!important;
    }
    .sidebar-trigger-section .address-place {
        max-width: 100%!important;
        margin-top: 10px;
    }
    .sidebar-trigger-section .delivery-active .order-button-group {
        justify-content: center;
    }
    .sidebar-trigger-section .search-section .order-btn-primary:last-child {
        margin-right: 0px!important;
    }
    .sidebar-trigger-section .display-contents .order-button-group {
        width: 100% !important;
        justify-content: space-between;
        display: flex;
        text-align: center;
    }
    .sidebar-trigger-section  .search-section .order-btn-primary {
        padding: 12px 20px;
    }
}

@media(max-width:768px) and (min-width:768px)
{
    .vendor-header .row .col-md-8
    {
        flex: 0 0 60%;
        max-width: 60%;
    }
    .vendor-header .row .col-md-4
    {
        flex: 0 0 40%;
        max-width: 40%;
    }
    .delivery-active2 {
        margin-top: 15px;
    }
   
    .without-delivery .display-contents .order-button-group {
        width: 28%!important;
    }
    .search-section.without-delivery  .res-search-wrapper{margin-top: 0px;}
}

@media(max-width:768px){
    .vendor-product-section .col-md-3.catagory-col {
        flex: 0 0 50%;
        max-width: 50%;
    }
}

@media(max-width:767px){
    .product-optional-items .checkbox {
        width: 50%;
        margin-left: 0px;
    }
    .vendor-product-container .product-category-content .product-category-img{max-width:120px;}
    .vendor-product-container .product-category-content .product-category-text {
        display: inline-block;
        width: calc(100% - 120px);
    }
    /* .product-category-menu{
        padding: 3px 15px;
        font-size:14px;
    } */
    .dynamic-price-section {
        width: 60%;
    }
    .add-to-cart-section {
        margin-top: 10px;
    }
    .vendor-product-container .product-category-content .product-category-text p{
        font-size: 12px;
    }
    .add-to-cart-section button{
        padding: 4px 15px;
        font-size: 14px;
    }
    .vendor-product-container .product-category-content .product-category-text h4 {
        font-size: 18px;
    }
    .product-category-option label {
        font-size: 13px;
    }
    .product-category-option select {
        font-size: 12px;
        margin-left: 8px;
        padding: 5px 5px;
    }
    .vendor-product-container .vendor-product-section .category-title{
        font-size:26px;
    }
    .vendor-res-img {
        height: 230px;
    }
    label.radio-label, label.check-label {
        font-size: 14px;
    }
}

@media(max-width:680opx)
{
    .cate-search-select,.delivery-active2 {
        width: 100%!important;
    }

}


@media(max-width:575px){
    .vendor-product-section .col-md-3.catagory-col {
        flex: 0 0 100%;
        max-width: 100%;
    }
    .vendor-product-container .vendor-product-section .category-title {
        text-align: left;
        font-size: 20px;
        margin: 5px 0px 10px 0px;
    }
    .res-search-wrapper input {
        width: 80%;
    }
}

@media(max-width:479px){
    .vendor-product-container .product-category-content .product-category-img{
        width:100%;
        max-width:100%;
        padding-right:0;
    }
    .vendor-product-container .product-category-content .product-category-text{
        width:100%;
        margin-top:10px;
    }
    .vendor-product-container .product-category-content{
        display: block;
    }
    
    /* .model-product-image {
        width: 310px;
        height: 250px;
    } */
    .dynamic-price-section {
        width: 100%;
        justify-content: space-between;
    }
    .model-button-wrapper {
        width: 100%;
        text-align: center;
    }
    .model-button-wrapper button.btn-orange.btn.btn-orange {
        width: 100%;
        margin-top: 8px;
    }
}
.vendor-product-breadcrumb {
    padding-top: 61px !important;
}
@media (max-width: 1947px) and (min-width: 1550px) {
    .sidebar-trigger.sidebar-trigger-section .vendor-product-container .vendor-product-section .catagory-col{
        flex: 0 0 33.33%;
        max-width: 33.33%;
    }
}

@media(max-width:1679px)
{
    .sidebar-trigger-section .search-section {
        flex-direction: column;
    }
    .sidebar-trigger-section  .display-contents {
        display: flex;
        width: 100%;
        justify-content: space-between;
    }
    .sidebar-trigger-section .search-section .res-search-wrapper {
        max-width: 100%;
        margin-top: 20px;
    }
    .sidebar-trigger-section .search-section-delivery .res-search-wrapper {
        max-width: 100%;
        margin-top: 0px;
    }
    .search-section .res-search-wrapper {
        width: 94% !important;
    }
    .sidebar-trigger-section .address-place {
        /* max-width: 50%; */
        max-width: 47%;
    }
    
    
   
}


@media (max-width: 1549px) and (min-width: 1200px) {
    .sidebar-trigger.sidebar-trigger-section .vendor-product-container .vendor-product-section .catagory-col{
        flex: 0 0 50%;
        max-width: 50%;
    }
}

@media (max-width: 1199px) and (min-width: 768px) {
    .sidebar-trigger.sidebar-trigger-section .vendor-product-container .vendor-product-section .catagory-col{
        flex: 0 0 100%;
        max-width: 100%;
    }
}



.overlayer{
    position:absolute;
    height: 100%;
    width: 100%;
    z-index: 9999;
    background-color: rgb(255 255 255 / 50%);

}
.search-section {
    padding: 20px;
    width: 100%;
    max-width: 1290px;
    margin: 0 auto;
    display: flex;
    font-family: 'Roboto', sans-serif;
    justify-content: center;
}
.search-section.search-section-delivery {
    flex-direction: unset;
}


.css-2613qy-menu{top:15px}
.address-place
{
    width: 100%;
    /* max-width: 268px; */
    max-width: 225px;
    margin-right: 10px;
}
.search-section .css-1wa3eu0-placeholder,.search-section .css-tlfecz-indicatorContainer {
    color: #000000;
}
.search-section .css-1okebmr-indicatorSeparator{
background-color: #000;
}
.search-section .res-search-wrapper input {
    color: #737373;
}
.css-yk16xz-control,.css-1pahdxg-control {
    padding: 5px;
}
.order-btn-primary {
    background: white;
    padding: 10px 10px;
    border: 1px solid #f7914d;
    color: #f7914d;
    border-radius: 5px;
    font-weight: 600;
    cursor: pointer;
}
.order-btn-primary:focus {
    outline: none;
}

.order-btn-primary.active {
    background: #f7914d;
    color: #ffffff;
}

.select-schedule .btn-group{
    width: 100%;
}
.select-schedule .btn-group .btn-primary{
    background-color: #ffffff;
    border-color: #f7914d;
    color: #f7914d;
    font-weight: 600;
}
.select-schedule .btn-group .btn-primary.active{
    background-color: #f7914d !important;
    color: #ffffff !important;
    border-color: #f7914d !important;
}
.select-schedule .btn-group .btn-primary:active{
    background-color: #f7914d !important;
    color: #ffffff !important;
    border-color: #f7914d !important;
}
.select-schedule .btn-group .btn-primary:focus{
    box-shadow: none !important;
}
.select-schedule .btn-group .btn-primary.active:focus{
    box-shadow: none !important;
}
.delivery-content label {
    display: block;
    font-size: 16px;
    font-weight: 500;
}
.delivery-content input[type=text], .delivery-content select {
    width: 100%;
    height: 48px;
    font-size: 14px;
    padding-left: 10px;
    border: 1px solid #ececec;
    color: #000;
    border-radius: 6px;
}
.time-date select {
    width: 48%;
}

.time-date {
    display: flex;
    width: 100%;
    justify-content: space-between;
    margin-bottom: 15px;
}
.schedule_model .modal-body {
    padding-bottom: 0px;
}
.delivery-content {
    padding-top: 25px;
}
label.light-label {
    font-weight: 300;
    font-size: 16px;
}
.or-div {
    text-align: center;
    position: relative;
    padding-bottom: 8px;
}
.or-div span {
    display: block;
    height: 25px;
    width: 25px;
    margin: 0 auto;
    background: #fff;
    border-radius: 25px;
    z-index: 11;
}
.or-div::after,.or-div::before
{
    content: '';
    position: absolute;
    height: 1px;
    width: 45%;
    z-index: 0;
    background-color: #e7e7e7;
    top: 10px;
}
.or-div::after
{
    right: 0;
}
.or-div::before
{
    left: 0;
}

  .location-div {
    display: inline-block;
    width: 20%;
}
  .location-div input
  {
      display: inline;
    width: 94%;
    height: 50px;
    border: 1px solid #ddd!important;
    padding-left: 20px;
    background: #ffffff;
    outline: none!important;
    box-shadow: none!important;
    border-radius: 10px;
  }
 
.display-contents {
    display: contents;
}
  /* .delivery-content .css-yk16xz-control,.delivery-content .css-1pahdxg-control{height: 50px;} */
  /* .delivery-content .css-g1d714-ValueContainer{height: 40px;} */

/* btn 
background-color: #007bff;
    border-color: #007bff; */

/** 06/01/2020 css start riddhi **/
.sidebar-trigger {
    background-color: #f0f1f5;
}
.product_details_bottom small {
    font-size: 11px;
    display: block;
    text-align: center;
    letter-spacing: 0.4px;
    margin-bottom: 5px;
}
span.product-count{
    display: table;
    background-color: #ff914d;
    color: #fff;
    font-weight: 500;
    border-radius: 6px;
    padding: 1.5px 7px 1.5px 8px;
    margin-left: auto;
    font-size: 14px;
}
.add-btn-product{
    border: 1px solid #ff914d;
    color: #000;
    font-size: 14px;
    background-color: transparent;
    border-radius: 2px;
    width: 85px;
    padding: 3.5px 6px; 
    margin: 6px 0 2px;
}
.main-search-section {
    background-color: #fff;
    border-top: 1px solid #c1c2c6;
}
.main-tab-wrapper .nav-tabs {
    border-bottom: 0;
    max-width: 1720px;
    margin: auto;
    padding: 0 8px;
}

.nav-tabs .nav-link{
color: #000;
background-color: #fff; 
border-radius: 4px;
margin: 0 6px;
border: 0;
font-size: 16px;
font-weight: 500;
font-family: 'Roboto', sans-serif;
}
.main-tab-wrapper .nav-tabs .nav-item.show .nav-link,.main-tab-wrapper .nav-tabs .nav-link.active{
    border: 0;
    background: #f7914d;
    color: #ffffff;
}
.search-section .order-btn-primary {
    font-weight: 500;
    padding: 12px 14px;
    margin-right: 10px!important;
}
.search-section .res-search-wrapper{
    margin-left: 0;
max-width: 674px;
width:100%;
}
.search-section .delivery-active1 {
    /* width:62% !important; */
    width:42% !important;
}
.delivery-active .order-button-group {
    /* width: 45%; */
}
.select-box-cate {
    display: inline;
}
.restaurent-wrapper {
    display: flex;
    max-width: 1255px;
    /* max-width: 1720px; */
    margin: 0 auto;
    align-items: center;
    padding: 10px 0px;
}
.res-logo img {
    width: 260px;
    height:auto;
}
.res-deatil h4 {
    margin-bottom: 0px;
    color: #242424;
    font-family: 'Roboto', sans-serif;
    font-size: 25px;
    }
    .res-deatil a {
    color: #f7914d !important;
    text-decoration: underline !important;
    cursor: pointer;
    }
    .res-deatil p {
        margin-bottom: 5px;
    }
    .res-search-wrapper.search-res-main {
        /* margin: 0 auto; */
        margin-top: 0 !important;
    }
.restaurent-wrapper .res-deatil {
    padding-left: 15px;
}
.row.header-row {
    border-bottom: 1px solid #cecfd3;
}
.color-red {
    color: red !important;
}
.m-t--5-i {
    margin-top: -5px !important;
}

/*22222*/
.modal-plus-minus-btn-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 15px;

}
.modal-plus-minus-btn {
    display: flex;
    align-items: center;
}
.modal-plus-minus-btn .subrow-price {
    margin-right: 15px;
    font-size: 13px;
}
.modal-plus-minus-btn .product-category-menu {
    border-radius: 0px;
}
.modal-plus-minus-btn .product-category-menu span,
.modal-plus-minus-btn .dynamic-price-section span.prod-cate-decrease,.modal-plus-minus-btn .dynamic-price-section span.prod-cate-increase
{
    padding: 3px 10px;
}
.modal-plus-minus-title span {
    font-size: 13px;
}
.modal-plus-minus-btn .product-category-menu span:nth-child(2) {
    background: #ffffff;
    font-size: 14px;
    padding: 3px 0px;
    width: 30px;
}

.cate-search-select {
    height: 50px;
    padding: 0 15px;
    margin-right: 10px;
    border: 1px solid #f7914d;
    border-radius: 5px;
    outline: none;
    width: 180px;
    text-overflow: ellipsis;
    word-wrap: unset;
}
.child-topping-box {
    width: 100%;
}
.display-contents {
    display: contents;
}
.radio_fancy input {
    opacity: 0;
}
.radio_fancy .radio_fancy_option {
    background: #ffffff;
    display: inline-block;
    /* margin: 0 10px; */
    width: 30px;
    height: 30px;
    border-radius: 50%;
}
.radio_fancy .radio_fancy_option input {
    width: 30px;
    height: 30px;
    vertical-align: top;
}

.radio_fancy .radio_fancy_option:last-child:before {
    content: '';
    width: 30px;
    height: 30px;
    background: #dadada;
    display: inline-block;
    position: absolute;
    border-radius: 50%;
    border: 2px solid #dadada;
    box-shadow: inset 0 0 0 3px #ffffff;
}
.radio_fancy .radio_fancy_option.check-topping-radio:last-child:before {
    background: #e49028;
    border: 2px solid #f7914d;
    box-shadow: inset 0 0 0 3px #ffffff;
}
.font-weight-300 {
    font-weight: 300;
}
.m-b-5 {
    margin-bottom: 5px;
}
.font-weight-700 {
    font-weight: 700;
}
/* .radio_fancy .radio_fancy_option.check-topping-radio:nth-child(2):after,.radio_fancy .radio_fancy_option.check-topping-radio:first-child:after {
    content: '';
    width: 30px;
    height: 30px;
    position: absolute;
    display: inline-block;
    border-radius: 50%;
    left: 0;
    border: 2px solid #f7914d;
    box-shadow: inset 0 0 0 3px #ffffff;
} */
/* .radio_fancy .radio_fancy_option:first-child:after,.radio_fancy .radio_fancy_option:nth-child(2):after
{
    content: '';
    width: 30px;
    height: 30px;
    position: absolute;
    display: inline-block;
    border-radius: 50%;
    left: 0;
    border: 2px solid #dadada;
    box-shadow: inset 0 0 0 3px #ffffff;
} */
.radio_fancy .radio_fancy_option:first-child:before {
    content: '';
    width: 30px;
    height: 30px;
    background: #ffffff;
    display: inline-block;
    position: absolute;
    border-radius: 50%;
    border: 2px solid #dadada;
    border-left: 15px solid #dadada;
}
.radio_fancy .radio_fancy_option.check-topping-radio:first-child:before {
    background: #ffffff;
    border: 2px solid #f7914d;
    border-left: 15px solid #f7914d;
}
.radio_fancy .radio_fancy_option:nth-child(2):before {
    content: '';
    width: 30px;
    height: 30px;
    background: #ffffff;
    display: inline-block;
    position: absolute;
    border-radius: 50%;
    border: 2px solid #dadada;
    border-right: 15px solid #dadada;
}
.radio_fancy .radio_fancy_option.check-topping-radio:nth-child(2):before
{
    background: #ffffff;
    border: 2px solid #f7914d;
    border-right: 15px solid #f7914d;
}
span.sub_topping_title span {
    font-size: 14px;
    color: #313438;
}

h5.childOptionTopping_name {
    display: flex;
}
.child-topping-box .childOptionTopping_name span.sub_topping_title_left {
    font-size: 13px;
    margin-top: 10px;
}
span.sub_topping_title_left {
    width: 40%;
}
.radio_fancy_wrapper,.sub_topping_title
{
    width:60%;
    display: flex;
}
.radio_fancy {
    width: 100%;
    display: flex;
}
span.sub_topping_title span,.radio_fancy_option {
    width: 33.33% !important;
    position: relative;
}
h5.childOptionTopping_name.toppingName_main {
    margin-bottom: 15px;
}
.check-lable-font-13 {
    font-size: 13px !important;
}
.width-100 {
    width: 100%;
}
@media (min-width: 768px){
    .product-box-model .modal-dialog {
        max-width: 692px;
        margin: 1.75rem auto;
    }
}
@media (max-width: 1599px){
    .main-tab-wrapper .col-md-3 {flex: 0 0 33.33%;max-width: 33.33%;}
}
@media (max-width: 420px){
    .product-optional-items .checkbox, .product-addition-options .radio {
    
        width: 100%;
    
    }
}
@media (max-width: 1199px){
    .product_small_img img {width: 70px;height: 70px;}
    .product_title h3{font-size: 14px;}
    .product_price p{font-size: 12px;}
    .add-btn-product{width: 70px;margin-top: 0;}
    .product_details_right {
        /* width: 52%; */
        padding-left: 10px;
    }
    .res-logo img {
        width: 290px;
        height:auto;
    }
}
@media (max-width: 991px){
    .vendor-product-section .col-md-3.catagory-col {flex: 0 0 50%;max-width: 50%;}
    .search-section {
        flex-wrap: wrap;
        justify-content: center;
    }
    .search-section .res-search-wrapper {
        max-width: 100% !important;
        margin-top: 15px;
    }
    .address-place {
        max-width: 40% !important;
    }
    .display-contents .order-button-group {
        width: 50% !important;
    }
    .address-place {
        max-width: 100% !important;
        margin-right: 0px;
        margin-top: 20px;
    }
    .cate-search-select-main {
        width: 100%;
        margin-bottom: 20px;
    }
    .cate-search-select-main .cate-search-select {
        width: 100%;
    }
}
@media (max-width: 767px){
    .time-date select {
        font-size: 13px;
    }
    .vendor-product-section .col-md-3.catagory-col {flex: 0 0 100%;max-width: 100%;}
    /* .product_details_right { width: 73%;} */
    .display-contents .order-button-group {
        width: 100% !important;
        justify-content: center;
        text-align: center;
    }
    .search-section .order-btn-primary:last-child {
        margin-right: 0px!important;
    }
    .res-search-wrapper input {
        width: 100%!important;
    }
    .address-place {
        max-width: 100% !important;
        margin-top: 10px;
    }
    .cate-search-select {
        margin-top: 15px;
        width:100%;
    }
    .search-section .res-search-wrapper,.cate-box.cate-box{
        max-width: 100%!important;
        width: 100%!important;
    }
    .restaurent-wrapper {
        display: block;
    }
    .res-logo {
        text-align: center;
    }
    .res-deatil {
        padding-top: 20px;
    }
    span.sub_topping_title span{
        font-size: 12px!important;
    }
    .radio_fancy_wrapper, .sub_topping_title {
        width: 50%;
    }
    span.sub_topping_title_left {
        width: 50%;
    }
    .product-addition-options .radio {
        width: 50%;
        margin-left: 0;
        margin-right: 0;
    }
}
@media (max-width: 480px){
.product_small_img img {width: 60px;height: 60px;}
/* .product_details_right {width: 57%;} */
}
@media(max-width:1278px){
    .delivery-active .order-button-group {width: auto;display: flex;}
    /* .address-place {max-width: 20%;} */
    .search-section .res-search-wrapper {max-width: 55%;}
    }

@media(max-width:1271px)
{
    .search-section .res-search-wrapper {max-width: 42%!important;}
    .address-place {
        max-width: 240px;
    }

}
@media(max-width:676px)
{
    .search-section .res-search-wrapper {
        max-width: 100%!important;
    }
}
    
    @media(max-width:360px) {
        .search-section .order-btn-primary {
            padding: 12px 12px;
        }
        /* .product_details_right {
            width: 52%;
        } */
    }
    @media(max-width:420px) {
        .product-addition-options .radio {
            width: 100%;
            margin-left: 0;
            margin-right: 0;
        }
        /* .product_details_right {
            width: 52%;
        } */
    }

    @media (min-width: 1280px) and (max-width: 1280px) {
        .sidebar-trigger-section .address-place {
            /* max-width: 50%; */
            max-width: 41%;
        }
        .search-section .res-search-wrapper {
            width: 42% !important;
        }
    }
    @media (min-width: 1024px) and (max-width: 1024px) {
        .sidebar-trigger-section .display-contents {
            display: unset;
            width: 100%;
            justify-content: space-between;
        }
        .sidebar-trigger-section .address-place {
            max-width: 81%;
            margin-top: 20px
        }
        .search-section .delivery-active1 {
            width: 81% !important;
        }
    }
/** 06/01/2020 css end riddhi **/

.order-receive-container {
    margin-top: 30px !important;
    width: 50%;
    background: #f1f2f7;
    margin: 0 auto;
    padding: 50px;
    text-align: center;
   /* position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);*/
}
.order-receive-container img {
    width: 140px;
    border-radius: 50%;
    box-shadow: 0px 1px 3px 5px rgba(222,222,222,0.5);
    margin-bottom: 30px;
}
.ml-3 a {
    color: #f7914d!important;
    text-decoration: none;
}
/*thankyou page*/
.container.google-container {
    width: 50%;
    border-top: 2px solid #ff914d;
    border-bottom: 2px solid #ff914d;
    margin-top: 30px;
    padding: 25px 0px;
}
.google-qr-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.google-qr-btn.apple-btn,.google-qr-btn,.logo-center {
    width: 20%;
}
.google-qr-wrapper img {
    width: 100%;
    height: auto;
}
.google-qr-btn img:first-child {
    margin-right: 10px;
}
.go-to-menu-btn {
    padding: 10px 20px;
    border: 1px solid #ff914d;
    background-color: #ff914d;
    color: white;
    border-radius: 10px;
    font-size: 20px;
    cursor: pointer;
}
.go-to-menu-btn:focus {
    outline: none;
}
@media(max-width:1024px)
{
    .order-receive-container,.container.google-container {
        width: 65%;
    }
    .google-qr-btn.apple-btn,.google-qr-btn,.logo-center {
        width: 33.33%;
    }
    .google-qr-wrapper img {
        width: 100%;
        height: auto;
    }
}
@media(max-width:767px)
{
    .order-receive-container,.container.google-container {
        width: 90%;
    }
    .order-receive-container img {
        margin-bottom: 20px;
    }
    /* .logo-center
    {
        display:none;
    } */
    .google-qr-btn.apple-btn,.google-qr-btn{
        width: 30%;
    }
}
@media(max-width:575px)
{
    .order-receive-container {
        padding: 15px;
    }
    .order-receive-container h2 {
        font-size: 20px;
    }
    .order-receive-container h3 {
        font-size: 24px;
    }
    .go-to-menu-btn {
        padding: 8px 15px;
        font-size: 17px;
    }
    .order-receive-container {
        margin-top: 15px !important;
    }
    .ml-3 {
        margin-top: 8px;
    }
}
.order_box_wrapper h3 {
    border-bottom: 1px solid #cecfd3;
    padding: 15px 0px;
    font-size: 20px;
    font-family: 'Roboto', sans-serif;
    font-weight: 700;
    padding-top: 0px;
    /* background: #f1f2f7; */
    /* margin-top: 25px; */
}
.row.header-row {
    border-bottom: 1px solid #cecfd3;
}
button.btn-orange.continue-shopping-btn {
    padding: 10px 15px;
    font-weight: 600;
}
.sq-creditcard {
    margin-top: 0px;
}
.tip-section {
    display: flex;
    justify-content: space-between;
    /* align-items: center; */
}
.order_box_left_wrapper h3{
    background: #f1f2f7;
    padding-top: 0px;
    margin-bottom: 15px;
}
.order_box_left_wrapper 
{
    background-color:#f1f2f7;
    padding: 30px;
    border-radius: 8px;
}
.header-row{background: #f1f2f7;}
.checkout-header {
    text-align: center;
    padding: 20px;
}
.checkout-header h1.text-center {
    position: relative;
    display: inline-block;
    padding-bottom: 6px;
    font-size: 28px;
}
.order-item-main {
    display: flex;
    border-bottom: 1px solid #cecfd3;
    margin-bottom: 15px;
}
.qty-wrapper {
    width: 5%;
}
.order-item-main .item-info-wrapper {
    padding-left: 0px;
    /* border-bottom: 1px solid #ececec;
    margin-bottom: 15px; */
    width: 95%;
}
/* .checkout-header h1.text-center::after
{
    position: absolute;
    content: '';
    height: 2px;
    width: 150px;
    background-color: #e0e0e0;
    left: 0;
    right: 0;
    bottom: 0;
    top: 70px;
    margin: auto;

} */
.food-name-price {
    display: flex;
    justify-content: space-between;
}
p.color_b {
    color: #000!important;
    font-size: 16px!important;
    font-weight: 700!important;
    font-family: 'Roboto', sans-serif;
}
.item-info-wrapper span,.item-info-wrapper p {
    font-weight: 300;
    font-size: 15px;
}
.item-info-wrapper p {
    font-size: 15px;
    /* color: #484847; */
    color: #636363;
}
.form-user-details input {
    width: 100%;
    border: 1px solid #ececec;
    height: 42px;
    border-radius: 8px;
    margin-bottom: 15px;
    padding-left: 15px;
    outline: none;
}
.form-user-details input::-webkit-input-placeholder {
    font-size: 14px;
}
.form-user-details input:-ms-input-placeholder {
    font-size: 14px;
}
.form-user-details input::-ms-input-placeholder {
    font-size: 14px;
}
.form-user-details input::placeholder {
    font-size: 14px;
}
.form-user-details {
    /* width: 50%;
    margin: 0 auto;*/
    border: 1px solid #f1f2f7; 
    padding: 25px 30px 30px;
    background-color: #f1f2f7;
    border-radius: 8px;
}
.user-details-container h4 {
    font-size: 18px;
   margin:20px 0px;
    font-weight: 700;
    font-family: 'Roboto', sans-serif;
}
.form-user-details label {
    display: block;
    font-family: 'Roboto', sans-serif;
    font-size: 16px;
    font-weight: 500;
    /* width: 50%; */
}
/* .payment-final {
    border-top: 5px solid #ececec;
    padding-top: 15px;
    margin-top: 15px;
} */
p.payment-p span {
    font-size: 16px;
    font-family: 'Roboto', sans-serif;
}
.payment-final p, .payment-final h5 {
    display: flex;
    justify-content: space-between;
}
.payment-btn {
    width: 100%;
    border: 1px solid #ececec;
    height: 35px;
    margin-top: 20px;
    margin-bottom: 20px;
}
.errorMsg {
    font-weight: 300;
    font-size: 15px;
    color: #ff0000;
}
.user-details-container form {
    margin-bottom: 15px;
}
.payment-final h5 {
    border-bottom: 1px solid #cecfd3;
    border-top: 1px solid #cecfd3;
    padding: 15px 0px;
    font-size: 20px;
    font-weight: 700;
}
.payment-final h5 span:last-child
{
    color:#ff914d;
}
p.payment-p.pr-payment span {
    font-size: 14px;
    color: #000;
}
/* p.payment-p:first-child {
    margin-bottom: 0px;
} */
/* .checkout-main-container
{
    position: relative;
} */
.spinner-wrapper {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    width: 100%;
    background: rgba(0,0,0,0.3)!important;
    height: 100vh;
    z-index: 1;
}
.spinner-wrapper svg,.spinner-wrapper p
{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
}
.sq-payment-form {
    color: #373F4A;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-weight: normal;
    position: relative;
    width: 100%;
}
span.sq-label:after {
    content: '*';
    position: absolute;
    right: -8px;
    color: #000;
    font-size: 16px;
}
.sq-label {
    position: relative;
    text-transform: capitalize !important;
    font-family: 'Roboto', sans-serif;
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 10px;
}
.tip-btn-group {
    /* background: #ececec; */
    padding: 4px;
    padding-left: 0px;
    border-radius: 5px;
    /* float: left; */
    display: flex;
}
.tip-btn-group .btn-primary {
    color: #ff914d;
    background-color: #ffffff;
    border-color: #ff914d;
    border-radius: 6px;
    font-size: 14px;
    padding: 8px 10px;
}
.btn-group.tip-btn-group div:first-child {
    margin-left: 0px;
}

.btn-group.tip-btn-group div {
    margin-left: 15px;
}
.tip-btn-group .btn-primary:hover {
    color: #fff !important;
    background-color: #ff914d !important;
    border-color: #ff914d !important;
    /* border-radius: 7px !important; */
}
.tip-btn-group .btn-primary:active {
    color: #fff !important;
    background-color: #ff914d !important;
    border-color: #ff914d !important;
}
.tip-btn-group .btn-primary:focus {
    box-shadow: none !important;
}
.tip-btn-group .btn-primary.active {
    color: #fff !important;
    background-color: #ff914d !important;
    border-color: #ff914d !important;
}
.custom-tip {
    float: left;
    margin-top: 10px;
    margin-left: 20px;
    cursor: pointer;
}
.custom-tip span {
    color: #ff914d;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}


.custom-tip-modal .body-custom-tip input {
    width: 100%;
    height: 44px;
    border: 1px solid #ececec;
    padding-left: 15px;
    outline: none;
    border-radius: 5px;
    /* border-bottom: 2px solid #ececec; */
}
.custom-tip-modal .body-custom-tip label {
    margin-bottom: 0px;
}
.custom-tip-modal .body-custom-tip {
    display: flex;
    align-items: center;
}
.custom-tip-modal .modal-footer button {
    outline: none;
    box-shadow: none!important;
    padding: 10px;
    width: 100px;
    text-align: center;
}
.custom-tip-modal button.cancel-btn.btn.btn-secondary {
    background: transparent!important;
    color: #000!important;
}
.custom-tip-modal button.add-btn.btn.btn-secondary {
    background: #ff914d;
    border-color: #ff914d;
}
.custom-tip-modal .modal-footer
{
    border-top: 0px;
    padding-top: 0!important;
    align-items: center;
    justify-content: center;

}
.breadcrumb-wrapper ul li {
    list-style: none;
    display: inline-block;
    padding-right: 10px;
    font-size: 14px;
    position: relative;
}

.breadcrumb-wrapper ul li a {
    text-decoration: none;
    color:#ff914d !important;
    cursor: pointer;
}
.breadcrumb-wrapper ul {
    margin: 0;
    padding: 0;
}
.breadcrumb-wrapper {
    padding: 6px 15px;
    background: #dfe2eb;
}
.continue-shopping-btn {
    border-radius: 5px;
}

.spinner-wrapper p {
    transform: translate(-50%,22%);
    top: 60%;
    width: 100%;
    text-align: center;
    color: #fff;
}
.sq-creditcard {
    background: #000000 !important;
    border: 1px solid #000000 !important;
    outline: none !important;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

/*09-01-2020*/
.checkout-container {
    max-width: 1248px;
    margin: 50px auto;
}
.food-name-price p.color_b {
    margin-bottom: 5px;
}
fieldset.sq-fieldset iframe {
    height: 42px;
    margin-bottom: 10px;
}
fieldset.sq-fieldset iframe  input[type="tel"] {
    padding: 8px 15px!important;
}
/* iframe input[type="tel"] {
    padding: 8px!important;
}
iframe input[type="text"] {
    padding: 8px!important;
} */
.sq-input {
    padding: 12px;
}
input.last-input-tel {
    margin-bottom: 0;
}
.m-b-30 {
    margin-bottom: 30px;
}
.font-weight-600 {
    font-weight: 600;
}
.couponcode-container {
    border-bottom: 1px solid #cecfd3;
    border-top: 1px solid #cecfd3;
    padding: 15px 0px;
    /* margin-top: 15px; */
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.couponcode-container .couponcode-left {
    float: left;
}
.couponcode-container .couponcode-left .deal-svg{
    height: 20px;
}
.couponcode-container .couponcode-left p{
    font-size: 14px;
    color: #000;
    display: inline;
    margin-left: 10px;
    margin-bottom:0;
}
.code_apply_content{
    display: flex;
    align-items: center;
}
.code_apply_content p{
    display: block !important;
}
.couponcode-container .couponcode-right {
    float: right;
}
.couponcode-container .couponcode-right .view-deals-link {
    color: #ff914d;
    font-weight: 600;
    cursor: pointer;
}
.add-more-item-link {
    float: right;
    color: #ff914d;
    font-weight: 600;
    cursor: pointer;
}
.input-group-deal .deal-input-gp-text {
    background-color: #ff914d;
    color: #ffffff;
    font-weight: 600;
    cursor: pointer;
}
.coupon-media {
    padding: 10px;
    background-color: #f1f2f7;
    border-radius: 15px;
    margin-bottom: 15px;
}
.pointer {
    cursor: pointer;
}
.text-red {
    color: red !important;
}
.m-t-30 {
    margin-top: 30px;
}
.m-t-15 {
    margin-top: 15px;
}
.input-common {
    width: 100%;
    border: 1px solid #ececec;
    height: 42px;
    border-radius: 8px;
    padding-left: 15px;
    outline: none;
}
.pickup-link {
    text-decoration: none;
    cursor: pointer;
}
.coupon-remove-link {
    float: right;
    color: red;
    cursor: pointer;
}
@media(max-width:767px)
{
    .pay-btn-wrapper {
        margin: 0px -15px;
        position: fixed;
        bottom: 0;
        width: 100%;
    }
    .sq-creditcard {
       border-radius: 0px;
    }

    .sq-payment-form {
        margin-bottom: 50px;
    }
    .checkout-container {
        margin: 30px auto;
    }
    .form-user-details input::-webkit-input-placeholder {
        font-size: 12px;
    }
    .form-user-details input:-ms-input-placeholder {
        font-size: 12px;
    }
    .form-user-details input::-ms-input-placeholder {
        font-size: 12px;
    }
    .form-user-details input::placeholder {
        font-size: 12px;
    }
    .pay-later-box {
        padding-bottom: 30px;
    }
    .user-permission-checkbox.checkbox label:before {
        position: absolute;
        left: 0px;
    }
    .user-permission-checkbox.checkbox label.radio-label, label.check-label {
        font-size: 14px;
    }
    .user-permission-checkbox.checkbox label.radio-label, label.check-label1 {
        padding-left: 30px;
    }
    
    .user-permission-checkbox.checkbox .checkbox input:checked + label:after
    {
        top:2px;
    }
}

@media(max-width:575px)
{
    .btn-group.tip-btn-group div {
        margin-left: 5px;
    }
    .payment-final h5,.order_box_wrapper h3 {
        font-size: 16px;
    }
    .qty-wrapper p,  p.color_b {
        font-size: 14px!important;
    }
    .form-user-details input {
        margin-bottom: 5px;
    }

  
}
@media(max-width:480px)
{
    .tip-btn-group .btn-primary {
        padding: 8px 6px;
    }
    .order_box_left_wrapper,.form-user-details {
        padding: 15px;
    }
    .form-user-details input::-webkit-input-placeholder {
        font-size: 10px;
    }
    .form-user-details input:-ms-input-placeholder {
        font-size: 10px;
    }
    .form-user-details input::-ms-input-placeholder {
        font-size: 10px;
    }
    .form-user-details input::placeholder {
        font-size: 10px;
    }
}
@media(max-width:360px)
{
    .tip-btn-group .btn-primary {
        padding: 7px 5px;
        font-size: 11px;
    }
}
@media (min-width: 576px)
{
.custom-tip-modal .modal-dialog {
    max-width: 360px;
    margin: 1.75rem auto;
}
}
.main-vendor-container {
  background: #fe8302;  /* fallback for old browsers */  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to right, #FFFFFF, #fe8302); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  position: relative;
  display: flex;
  align-items: center;
  padding: 15px;
  cursor: pointer;
    border: 1px solid #eaeaea;
    border-radius: 10px;
    /* background: #dedede; */
    margin-bottom: 30px;
}
/* .main-vendor-container:hover {
  box-shadow: 0px 0px 30px rgba(0,0,0,0.3);
} */
.main-vendor-container .vendor-image-section {
  display: inline-block;
  width: 150px;
  height: 150px;
  position: relative;
}
.main-vendor-container .vendor-title {
  position: relative;
  top: 0;
  bottom: auto;
  margin: auto;
  left: 0;
  right: auto;
  transform: none;
  color: black;
  text-align: left;
  padding: 10px 5px;
  font-size: 24px;
}
.main-vendor-container .vendor-image-section .vendor-image {
  /* max-width: 100%; */
  height: 100%;
  width: 150px;
  object-fit: scale-down;
  /* position: absolute;
    left: 50%;
    max-width: 100%;
    max-height: 100%;
    top: 50%;
    transform: translate(-50%, -50%); */
}
.res-info {
  padding-left: 15px;
}
ul.res-tag {
  margin: 0;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
}
ul.res-tag li {
  list-style: none;
  width: auto;
  margin-left: 10px; 
  border: 1px solid #f8a500;
  padding: 0px 12px;
  line-height: 15px;
  background: #f8a500;
  color: #fff;
  font-size: 13px;
  border-radius: 15px;
  margin-bottom: 5px;
}
ul.res-tag li:first-child
{
  margin-left: 0px;
}
.res-info p {
  margin-bottom: 5px;
}
.row.resturant-list-row {
  margin-top: 80px;
}

@media(max-width:1199px)
{
  .resturant-list-row .col-md-3 {
    flex: 0 0 33.33%;
    max-width: 33.33%;
}
}


@media(max-width:768px)
{
  .resturant-list-row .col-md-3 {
    flex: 0 0 50%;
    max-width: 50%;
}
}
@media(max-width:575px)
{
  .resturant-list-row .col-md-3 {
    flex: 0 0 100%;
    max-width: 100%;
}
.resturant-list-row .col-md-3 .main-vendor-container {
  margin-bottom: 15px;;
}
.resturant-list-row .col-md-3:first-child .main-vendor-container
  {
    margin-top: 20px;
  }
  .row.resturant-list-row {
    margin-top: 50px;
}
}

@media (max-width: 1947px) and (min-width: 1550px) {
  .sidebar-trigger.sidebar-trigger-section .resturant-list-row .col-vendor{
      flex: 0 0 33.33%;
      max-width: 33.33%;
  }
}
@media (max-width: 1549px) and (min-width: 1200px) {
  .sidebar-trigger.sidebar-trigger-section .resturant-list-row .col-vendor{
      flex: 0 0 50%;
      max-width: 50%;
  }
}

@media (max-width: 1199px) and (min-width: 768px) {
  .sidebar-trigger.sidebar-trigger-section .resturant-list-row .col-vendor{
      flex: 0 0 100%;
      max-width: 100%;
  }
}

  #payment-form {
    /* max-width: 550px;
    min-width: 300px;
    margin: 150px auto; */
  }
  
  .buyer-inputs {
    display: flex;
    grid-gap: 20px;
    gap: 20px;
    justify-content: space-between;
    border: none;
    margin: 0;
    padding: 0;
  }
  .sq-card-message {
    display: none;
  }
  #card-container {
    margin-top: 30px;
    /* this height depends on the size of the container element */
    /* We transition from a single row to double row at 485px */
    /* Settting this min-height minimizes the impact of the card form loading */
    min-height: 80px;
  }
  
  #gift-card-container {
    margin-top: 45px;
    min-height: 90px;
  }
  
  @media screen and (max-width: 500px) {
    #card-container {
      min-height: 140px;
    }
  }
  
  #ach-button {
    margin-top: 20px;
  }
  
  #landing-page-layout {
    width: 80%;
    margin: 150px auto;
    max-width: 1000px;
  }
  
  #its-working {
    color: #737373;
  }
  
  #example-container {
    width: 100%;
    border: 1px solid #b3b3b3;
    padding: 48px;
    margin: 32px 0;
    border-radius: 12px;
  }
  
  #example-list {
    display: flex;
    flex-direction: column;
    grid-gap: 15px;
    gap: 15px;
  }
  
  #customer-input {
    margin-bottom: 40px;
  }
  
  #card-input {
    margin-top: 0;
    margin-bottom: 40px;
  }
  
  #payment-form h3 {
    margin: 0;
  }
  
  #payment-form p {
    line-height: 24px;
  }
  
  #payment-form label {
    font-size: 12px;
    width: 100%;
  }
  
  #payment-form input {
    padding: 12px;
    width: 100%;
    border-radius: 5px;
    border-width: 1px;
    margin-top: 20px;
    font-size: 16px;
    border: 1px solid rgba(0, 0, 0, 0.15);
  }
  
  #payment-form input:focus {
    border: 1px solid #000000;
  }
  
  .card-button-css {
    color: #ffffff;
    background-color: #000000;
    border-radius: 5px;
    cursor: pointer;
    border-style: none;
    -webkit-user-select: none;
        -ms-user-select: none;
            user-select: none;
    outline: none;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    padding: 12px;
    width: 100%;
    box-shadow: 1px;
  }
  
  /* .card-button-css:active {
    background-color: rgb(0, 85, 204);
  } */
  
  .card-button-css:disabled {
    background-color: rgba(0, 0, 0, 0.05);
    color: rgba(0, 0, 0, 0.3);
  }
  
  #payment-status-container {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid rgba(0, 0, 0, 0.05);
    box-sizing: border-box;
    border-radius: 50px;
    margin: 0 auto;
    width: 225px;
    height: 48px;
    visibility: hidden;
  }
  
  #payment-status-container.missing-credentials {
    width: 350px;
  }
  
  #payment-status-container.is-success:before {
    content: '';
    background-color: #00b23b;
    width: 16px;
    height: 16px;
    margin-right: 16px;
    -webkit-mask: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16ZM11.7071 6.70711C12.0968 6.31744 12.0978 5.68597 11.7093 5.29509C11.3208 4.90422 10.6894 4.90128 10.2973 5.28852L11 6C10.2973 5.28852 10.2973 5.28853 10.2973 5.28856L10.2971 5.28866L10.2967 5.28908L10.2951 5.29071L10.2886 5.29714L10.2632 5.32224L10.166 5.41826L9.81199 5.76861C9.51475 6.06294 9.10795 6.46627 8.66977 6.90213C8.11075 7.4582 7.49643 8.07141 6.99329 8.57908L5.70711 7.29289C5.31658 6.90237 4.68342 6.90237 4.29289 7.29289C3.90237 7.68342 3.90237 8.31658 4.29289 8.70711L6.29289 10.7071C6.68342 11.0976 7.31658 11.0976 7.70711 10.7071L11.7071 6.70711Z' fill='black' fill-opacity='0.9'/%3E%3C/svg%3E");
    mask: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16ZM11.7071 6.70711C12.0968 6.31744 12.0978 5.68597 11.7093 5.29509C11.3208 4.90422 10.6894 4.90128 10.2973 5.28852L11 6C10.2973 5.28852 10.2973 5.28853 10.2973 5.28856L10.2971 5.28866L10.2967 5.28908L10.2951 5.29071L10.2886 5.29714L10.2632 5.32224L10.166 5.41826L9.81199 5.76861C9.51475 6.06294 9.10795 6.46627 8.66977 6.90213C8.11075 7.4582 7.49643 8.07141 6.99329 8.57908L5.70711 7.29289C5.31658 6.90237 4.68342 6.90237 4.29289 7.29289C3.90237 7.68342 3.90237 8.31658 4.29289 8.70711L6.29289 10.7071C6.68342 11.0976 7.31658 11.0976 7.70711 10.7071L11.7071 6.70711Z' fill='black' fill-opacity='0.9'/%3E%3C/svg%3E");
  }
  
  #payment-status-container.is-success:after {
    content: 'Payment successful';
    font-size: 14px;
    line-height: 16px;
  }
  
  #payment-status-container.is-failure:before {
    content: '';
    background-color: #cc0023;
    width: 16px;
    height: 16px;
    margin-right: 16px;
    -webkit-mask: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16ZM5.70711 4.29289C5.31658 3.90237 4.68342 3.90237 4.29289 4.29289C3.90237 4.68342 3.90237 5.31658 4.29289 5.70711L6.58579 8L4.29289 10.2929C3.90237 10.6834 3.90237 11.3166 4.29289 11.7071C4.68342 12.0976 5.31658 12.0976 5.70711 11.7071L8 9.41421L10.2929 11.7071C10.6834 12.0976 11.3166 12.0976 11.7071 11.7071C12.0976 11.3166 12.0976 10.6834 11.7071 10.2929L9.41421 8L11.7071 5.70711C12.0976 5.31658 12.0976 4.68342 11.7071 4.29289C11.3166 3.90237 10.6834 3.90237 10.2929 4.29289L8 6.58579L5.70711 4.29289Z' fill='black' fill-opacity='0.9'/%3E%3C/svg%3E%0A");
    mask: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16ZM5.70711 4.29289C5.31658 3.90237 4.68342 3.90237 4.29289 4.29289C3.90237 4.68342 3.90237 5.31658 4.29289 5.70711L6.58579 8L4.29289 10.2929C3.90237 10.6834 3.90237 11.3166 4.29289 11.7071C4.68342 12.0976 5.31658 12.0976 5.70711 11.7071L8 9.41421L10.2929 11.7071C10.6834 12.0976 11.3166 12.0976 11.7071 11.7071C12.0976 11.3166 12.0976 10.6834 11.7071 10.2929L9.41421 8L11.7071 5.70711C12.0976 5.31658 12.0976 4.68342 11.7071 4.29289C11.3166 3.90237 10.6834 3.90237 10.2929 4.29289L8 6.58579L5.70711 4.29289Z' fill='black' fill-opacity='0.9'/%3E%3C/svg%3E%0A");
  }
  
  #payment-status-container.is-failure:after {
    content: 'Payment failed';
    font-size: 14px;
    line-height: 16px;
  }
  
  #payment-status-container.missing-credentials:before {
    content: '';
    background-color: #cc0023;
    width: 16px;
    height: 16px;
    margin-right: 16px;
    -webkit-mask: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16ZM5.70711 4.29289C5.31658 3.90237 4.68342 3.90237 4.29289 4.29289C3.90237 4.68342 3.90237 5.31658 4.29289 5.70711L6.58579 8L4.29289 10.2929C3.90237 10.6834 3.90237 11.3166 4.29289 11.7071C4.68342 12.0976 5.31658 12.0976 5.70711 11.7071L8 9.41421L10.2929 11.7071C10.6834 12.0976 11.3166 12.0976 11.7071 11.7071C12.0976 11.3166 12.0976 10.6834 11.7071 10.2929L9.41421 8L11.7071 5.70711C12.0976 5.31658 12.0976 4.68342 11.7071 4.29289C11.3166 3.90237 10.6834 3.90237 10.2929 4.29289L8 6.58579L5.70711 4.29289Z' fill='black' fill-opacity='0.9'/%3E%3C/svg%3E%0A");
    mask: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16ZM5.70711 4.29289C5.31658 3.90237 4.68342 3.90237 4.29289 4.29289C3.90237 4.68342 3.90237 5.31658 4.29289 5.70711L6.58579 8L4.29289 10.2929C3.90237 10.6834 3.90237 11.3166 4.29289 11.7071C4.68342 12.0976 5.31658 12.0976 5.70711 11.7071L8 9.41421L10.2929 11.7071C10.6834 12.0976 11.3166 12.0976 11.7071 11.7071C12.0976 11.3166 12.0976 10.6834 11.7071 10.2929L9.41421 8L11.7071 5.70711C12.0976 5.31658 12.0976 4.68342 11.7071 4.29289C11.3166 3.90237 10.6834 3.90237 10.2929 4.29289L8 6.58579L5.70711 4.29289Z' fill='black' fill-opacity='0.9'/%3E%3C/svg%3E%0A");
  }
  
  #payment-status-container.missing-credentials:after {
    content: 'applicationId and/or locationId is incorrect';
    font-size: 14px;
    line-height: 16px;
  }
  
  #payment-status-container.is-success.store-card-message:after {
    content: 'Store card successful';
  }
  
  #payment-status-container.is-failure.store-card-message:after {
    content: 'Store card failed';
  }
  
  #afterpay-button {
    height: 40px;
  }
  

.radio-wrapper {
  width: 100%;
  height: 100vh;
  overflow: hidden;
  position: relative;
  background-color: #f1f2f7;
}
.radio-wrapper form {
  border: 1px solid #ececec;
  width: auto;
  padding: 25px 50px;
  height: auto;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background: #fff;
  border-radius: 10px;
}
.radio-wrapper .radiodelivery {
  margin: .5rem 0;
  display: block;
  justify-content: space-between;
  align-items: center;
      margin-bottom: 20px;
}
.radio-wrapper .radiodelivery input[type=radio] {
  position: absolute;
  opacity: 0;
  right: 15px;
}
.radio-wrapper .delivery-content label {
  display: block;
  font-weight: 500;

}
.radio-wrapper .radiodelivery-label {
  font-weight: 400!important;
  width: 100%;
  position: relative;
  padding-left: 35px;
    font-size: 18px;
}
.radio-wrapper .radiodelivery input[type=radio]:checked+.radiodelivery-label:before {
  background-color: #9e9d9d!important;
  box-shadow: inset 0 0 0 4px #f4f4f4;
  border: 1px solid #ececec!important;
}
.radio-wrapper .radiodelivery input[type=radio]+.radiodelivery-label:before {
    content: "";
  background: #fff;
  border-radius: 100%;
  border: 1px solid #b4b4b4;
  display: inline-block;
  width: 22px;
  height: 22px;
  position: absolute;
  top: 4px;
  left: 0;
  margin-right: 1em;
  vertical-align: top;
  cursor: pointer;
  text-align: center;
  transition: all .25s ease;
}
.radio-wrapper .submit-btn-wrapper button {
  padding: 10px 24px;
  font-size: 16px;
  border: 1px solid #5d5d5d;
  background-color: #5d5d5d;
  color: #fff;
  border-radius: 4px;
  outline: none;
  cursor: pointer;
}
.radio-wrapper a.botttom-link {
  display: inline-block;
  margin-top: 15px;
  color: #ff914d;
  text-decoration: none;
}
textarea.other-text {
  width: 100%;
  border: 1px solid #ececec;
  resize: none;
  margin-bottom: 12px;
  border-radius: 5px;
  outline: none;
}
.success-msg {
  text-align: center;
  background: #f1f2f7;
  padding: 15px;
}
.success-msg img {
  width: 140px;
}
@media(max-width: 767px){
  .radio-wrapper form {width: 85%;}
}
.single_msg_wrapper{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    overflow: hidden;
    text-align: center;
}
p.single_msg {
    /* background-color: #fafafa; */
    margin-bottom: 0px;
    padding: 0% 50px;
    /* box-shadow: 0px 0px 10px 45px rgb(0 0 0 / 5%); */
    /* -webkit-box-shadow: 0px 0px 10px 45px rgb(0 0 0 / 5%); */
}
.new-form-wrapper.add_cash_payment label {
    width: 100%;
    text-align: left;
}
.new-form-wrapper .form-title {
    margin-bottom: 15px;
}
.new-form-wrapper.add_cash_payment {
    width: 35%;
    margin: 0 auto;
}
.new-form-wrapper.add_cash_payment  h1.form-title {
    font-size: 30px;
}
.c_info {
    background-color: #fafafa;
    padding: 13px;
    width: 35%;
    margin: 15px auto;
}
.c_info p {
    margin-bottom: 5px;
    font-size: 15px;
}
.c_info h3 {
    font-size: 24px;
    padding-bottom: 5px;
    position: relative;
}
.c_info h3::after
{
    content: '';
    position: absolute;
    height: 4px;
    width: 84px;
    background-color: #f7904dde;
    left: 50%;
    transform: translateX(-50%);
    bottom: -4px;
}
@media(max-width:1199px)
{
    .new-form-wrapper.add_cash_payment,.c_info {
        width: 50%;
    }
}

@media(max-width:767px)
{
    .new-form-wrapper h1.form-title {
        font-size: 30px;
    }
    .new-form-wrapper.add_cash_payment,.c_info {
        width: 65%;
    }
}

@media(max-width:575px)
{
    .new-form-wrapper.add_cash_payment,.c_info {
        width: 90%;
    }
}
* {
    box-sizing: border-box;
  }
  
  body {
    padding: 0;
    margin: 0;
  }
  
  #notfound {
    position: relative;
    height: 100vh;
  }
  
  #notfound .notfound {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
  
  .notfound {
    max-width: 520px;
    width: 100%;
    line-height: 1.4;
    text-align: center;
  }
  
  .notfound .notfound-404 {
    position: relative;
    height: 240px;
  }
  
  .notfound .notfound-404 h1 {
    font-family: 'Montserrat', sans-serif;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    font-size: 252px;
    font-weight: 900;
    margin: 0px;
    color: #262626;
    text-transform: uppercase;
    letter-spacing: -40px;
    margin-left: -20px;
  }
  
  .notfound .notfound-404 h1>span {
    text-shadow: -8px 0px 0px #fff;
  }
  
  .notfound .notfound-404 h3 {
    font-family: 'Cabin', sans-serif;
    position: relative;
    font-size: 16px;
    font-weight: 700;
    text-transform: uppercase;
    color: #262626;
    margin: 0px;
    letter-spacing: 3px;
    padding-left: 6px;
  }
  
  .notfound h2 {
    font-family: 'Cabin', sans-serif;
    font-size: 20px;
    font-weight: 400;
    text-transform: uppercase;
    color: #000;
    margin-top: 0px;
    margin-bottom: 25px;
  }
  
  @media only screen and (max-width: 767px) {
    .notfound .notfound-404 {
      height: 200px;
    }
    .notfound .notfound-404 h1 {
      font-size: 200px;
    }
  }
  
  @media only screen and (max-width: 480px) {
    .notfound .notfound-404 {
      height: 162px;
    }
    .notfound .notfound-404 h1 {
      font-size: 162px;
      height: 150px;
      line-height: 162px;
    }
    .notfound h2 {
      font-size: 16px;
    }
  }
  
.new-form-wrapper .form-wrapper {
    border: 1px solid #ececec;
    padding: 25px;
}
.invalid{
    border-color: #db3c36 !important;
}
.new-form-wrapper {
    /* width: 60%;
    position: absolute;
    top: 50%;
    bottom: 0px;
    left: 50%;
    transform: translate(-50%, -50%); */
}
.form-wrapper form {
    margin-bottom: 0;
}
h1.form-title {
    text-align: center;
    font-weight: 500;
}

/* .form-wrapper .form-group {
    margin-bottom: 15px;
} */

.form-wrapper .form-group input {
    width: 100%;
    border: 1px solid #dedede;
    height: 47px;
    padding-left: 15px;
    font-size: 16px;
    font-weight: 400;
    outline: none;
    box-shadow: none;
}

.form-wrapper button {
    border: 1px solid #dadada!important;
    height: 42px;
    width: 100%;
    text-transform: uppercase;
    box-shadow: none;
    cursor: pointer;
    background-color: black;
    color: white !important;
}

.submit-btn-wrapper {
    text-align: right;
}
.invalid-feedback{
    color: #db3c36;
    display: block !important;
    font-size: 15px !important;
}
.squre-form-wrapper {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
@media(max-width:767px)
{
    .squre-form-wrapper {
        position: relative;
        top: 15px;
        left: 0px;
        transform: inherit;
    }
}
.form-wrapper .customer-checkbox .input-checkbox {
    width: auto;
    height: auto;
    margin-right: 10px;
    margin-bottom: 0;
    display: none;
    cursor: pointer;
}
.form-wrapper .customer-checkbox .input-checkbox-label {
    cursor: pointer;
    position: relative;
    font-size: 12px;
}

.input-checkbox-label.input-checkbox-label:before {
    border: 1px solid #ff914d !important;
    content: "";
    content: '';
    -webkit-appearance: none;
    padding: 10px;
    display: inline-block;
    position: relative;
    vertical-align: middle;
    cursor: pointer;
    margin-right: 4px;
    background-color: transparent;
    box-sizing: border-box;
}
.form-wrapper .customer-checkbox input:checked + label:after {
    content: '';
    display: block;
    position: absolute;
    top: 2px;
    left: 7px;
    width: 9px;
    height: 15px;
    border: solid #ff914d;
    border-width: 0 4px 4px 0;
    transform: rotate(45deg);
    box-sizing: border-box;
}
.font-20 {
    font-size: 20px;
}
.font-30 {
    font-size: 30px;
}
body {
  margin: 0;
  font-family: 'Roboto', sans-serif !important;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

