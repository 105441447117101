.order_box_wrapper h3 {
    border-bottom: 1px solid #cecfd3;
    padding: 15px 0px;
    font-size: 20px;
    font-family: 'Roboto', sans-serif;
    font-weight: 700;
    padding-top: 0px;
    /* background: #f1f2f7; */
    /* margin-top: 25px; */
}
.row.header-row {
    border-bottom: 1px solid #cecfd3;
}
button.btn-orange.continue-shopping-btn {
    padding: 10px 15px;
    font-weight: 600;
}
.sq-creditcard {
    margin-top: 0px;
}
.tip-section {
    display: flex;
    justify-content: space-between;
    /* align-items: center; */
}
.order_box_left_wrapper h3{
    background: #f1f2f7;
    padding-top: 0px;
    margin-bottom: 15px;
}
.order_box_left_wrapper 
{
    background-color:#f1f2f7;
    padding: 30px;
    border-radius: 8px;
}
.header-row{background: #f1f2f7;}
.checkout-header {
    text-align: center;
    padding: 20px;
}
.checkout-header h1.text-center {
    position: relative;
    display: inline-block;
    padding-bottom: 6px;
    font-size: 28px;
}
.order-item-main {
    display: flex;
    border-bottom: 1px solid #cecfd3;
    margin-bottom: 15px;
}
.qty-wrapper {
    width: 5%;
}
.order-item-main .item-info-wrapper {
    padding-left: 0px;
    /* border-bottom: 1px solid #ececec;
    margin-bottom: 15px; */
    width: 95%;
}
/* .checkout-header h1.text-center::after
{
    position: absolute;
    content: '';
    height: 2px;
    width: 150px;
    background-color: #e0e0e0;
    left: 0;
    right: 0;
    bottom: 0;
    top: 70px;
    margin: auto;

} */
.food-name-price {
    display: flex;
    justify-content: space-between;
}
p.color_b {
    color: #000!important;
    font-size: 16px!important;
    font-weight: 700!important;
    font-family: 'Roboto', sans-serif;
}
.item-info-wrapper span,.item-info-wrapper p {
    font-weight: 300;
    font-size: 15px;
}
.item-info-wrapper p {
    font-size: 15px;
    /* color: #484847; */
    color: #636363;
}
.form-user-details input {
    width: 100%;
    border: 1px solid #ececec;
    height: 42px;
    border-radius: 8px;
    margin-bottom: 15px;
    padding-left: 15px;
    outline: none;
}
.form-user-details input::placeholder {
    font-size: 14px;
}
.form-user-details {
    /* width: 50%;
    margin: 0 auto;*/
    border: 1px solid #f1f2f7; 
    padding: 25px 30px 30px;
    background-color: #f1f2f7;
    border-radius: 8px;
}
.user-details-container h4 {
    font-size: 18px;
   margin:20px 0px;
    font-weight: 700;
    font-family: 'Roboto', sans-serif;
}
.form-user-details label {
    display: block;
    font-family: 'Roboto', sans-serif;
    font-size: 16px;
    font-weight: 500;
    /* width: 50%; */
}
/* .payment-final {
    border-top: 5px solid #ececec;
    padding-top: 15px;
    margin-top: 15px;
} */
p.payment-p span {
    font-size: 16px;
    font-family: 'Roboto', sans-serif;
}
.payment-final p, .payment-final h5 {
    display: flex;
    justify-content: space-between;
}
.payment-btn {
    width: 100%;
    border: 1px solid #ececec;
    height: 35px;
    margin-top: 20px;
    margin-bottom: 20px;
}
.errorMsg {
    font-weight: 300;
    font-size: 15px;
    color: #ff0000;
}
.user-details-container form {
    margin-bottom: 15px;
}
.payment-final h5 {
    border-bottom: 1px solid #cecfd3;
    border-top: 1px solid #cecfd3;
    padding: 15px 0px;
    font-size: 20px;
    font-weight: 700;
}
.payment-final h5 span:last-child
{
    color:#ff914d;
}
p.payment-p.pr-payment span {
    font-size: 14px;
    color: #000;
}
/* p.payment-p:first-child {
    margin-bottom: 0px;
} */
/* .checkout-main-container
{
    position: relative;
} */
.spinner-wrapper {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    width: 100%;
    background: rgba(0,0,0,0.3)!important;
    height: 100vh;
    z-index: 1;
}
.spinner-wrapper svg,.spinner-wrapper p
{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
}
.sq-payment-form {
    color: #373F4A;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-weight: normal;
    position: relative;
    width: 100%;
}
span.sq-label:after {
    content: '*';
    position: absolute;
    right: -8px;
    color: #000;
    font-size: 16px;
}
.sq-label {
    position: relative;
    text-transform: capitalize !important;
    font-family: 'Roboto', sans-serif;
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 10px;
}
.tip-btn-group {
    /* background: #ececec; */
    padding: 4px;
    padding-left: 0px;
    border-radius: 5px;
    /* float: left; */
    display: flex;
}
.tip-btn-group .btn-primary {
    color: #ff914d;
    background-color: #ffffff;
    border-color: #ff914d;
    border-radius: 6px;
    font-size: 14px;
    padding: 8px 10px;
}
.btn-group.tip-btn-group div:first-child {
    margin-left: 0px;
}

.btn-group.tip-btn-group div {
    margin-left: 15px;
}
.tip-btn-group .btn-primary:hover {
    color: #fff !important;
    background-color: #ff914d !important;
    border-color: #ff914d !important;
    /* border-radius: 7px !important; */
}
.tip-btn-group .btn-primary:active {
    color: #fff !important;
    background-color: #ff914d !important;
    border-color: #ff914d !important;
}
.tip-btn-group .btn-primary:focus {
    box-shadow: none !important;
}
.tip-btn-group .btn-primary.active {
    color: #fff !important;
    background-color: #ff914d !important;
    border-color: #ff914d !important;
}
.custom-tip {
    float: left;
    margin-top: 10px;
    margin-left: 20px;
    cursor: pointer;
}
.custom-tip span {
    color: #ff914d;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}


.custom-tip-modal .body-custom-tip input {
    width: 100%;
    height: 44px;
    border: 1px solid #ececec;
    padding-left: 15px;
    outline: none;
    border-radius: 5px;
    /* border-bottom: 2px solid #ececec; */
}
.custom-tip-modal .body-custom-tip label {
    margin-bottom: 0px;
}
.custom-tip-modal .body-custom-tip {
    display: flex;
    align-items: center;
}
.custom-tip-modal .modal-footer button {
    outline: none;
    box-shadow: none!important;
    padding: 10px;
    width: 100px;
    text-align: center;
}
.custom-tip-modal button.cancel-btn.btn.btn-secondary {
    background: transparent!important;
    color: #000!important;
}
.custom-tip-modal button.add-btn.btn.btn-secondary {
    background: #ff914d;
    border-color: #ff914d;
}
.custom-tip-modal .modal-footer
{
    border-top: 0px;
    padding-top: 0!important;
    align-items: center;
    justify-content: center;

}
.breadcrumb-wrapper ul li {
    list-style: none;
    display: inline-block;
    padding-right: 10px;
    font-size: 14px;
    position: relative;
}

.breadcrumb-wrapper ul li a {
    text-decoration: none;
    color:#ff914d !important;
    cursor: pointer;
}
.breadcrumb-wrapper ul {
    margin: 0;
    padding: 0;
}
.breadcrumb-wrapper {
    padding: 6px 15px;
    background: #dfe2eb;
}
.continue-shopping-btn {
    border-radius: 5px;
}

.spinner-wrapper p {
    transform: translate(-50%,22%);
    top: 60%;
    width: 100%;
    text-align: center;
    color: #fff;
}
.sq-creditcard {
    background: #000000 !important;
    border: 1px solid #000000 !important;
    outline: none !important;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

/*09-01-2020*/
.checkout-container {
    max-width: 1248px;
    margin: 50px auto;
}
.food-name-price p.color_b {
    margin-bottom: 5px;
}
fieldset.sq-fieldset iframe {
    height: 42px;
    margin-bottom: 10px;
}
fieldset.sq-fieldset iframe  input[type="tel"] {
    padding: 8px 15px!important;
}
/* iframe input[type="tel"] {
    padding: 8px!important;
}
iframe input[type="text"] {
    padding: 8px!important;
} */
.sq-input {
    padding: 12px;
}
input.last-input-tel {
    margin-bottom: 0;
}
.m-b-30 {
    margin-bottom: 30px;
}
.font-weight-600 {
    font-weight: 600;
}
.couponcode-container {
    border-bottom: 1px solid #cecfd3;
    border-top: 1px solid #cecfd3;
    padding: 15px 0px;
    /* margin-top: 15px; */
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.couponcode-container .couponcode-left {
    float: left;
}
.couponcode-container .couponcode-left .deal-svg{
    height: 20px;
}
.couponcode-container .couponcode-left p{
    font-size: 14px;
    color: #000;
    display: inline;
    margin-left: 10px;
    margin-bottom:0;
}
.code_apply_content{
    display: flex;
    align-items: center;
}
.code_apply_content p{
    display: block !important;
}
.couponcode-container .couponcode-right {
    float: right;
}
.couponcode-container .couponcode-right .view-deals-link {
    color: #ff914d;
    font-weight: 600;
    cursor: pointer;
}
.add-more-item-link {
    float: right;
    color: #ff914d;
    font-weight: 600;
    cursor: pointer;
}
.input-group-deal .deal-input-gp-text {
    background-color: #ff914d;
    color: #ffffff;
    font-weight: 600;
    cursor: pointer;
}
.coupon-media {
    padding: 10px;
    background-color: #f1f2f7;
    border-radius: 15px;
    margin-bottom: 15px;
}
.pointer {
    cursor: pointer;
}
.text-red {
    color: red !important;
}
.m-t-30 {
    margin-top: 30px;
}
.m-t-15 {
    margin-top: 15px;
}
.input-common {
    width: 100%;
    border: 1px solid #ececec;
    height: 42px;
    border-radius: 8px;
    padding-left: 15px;
    outline: none;
}
.pickup-link {
    text-decoration: none;
    cursor: pointer;
}
.coupon-remove-link {
    float: right;
    color: red;
    cursor: pointer;
}
@media(max-width:767px)
{
    .pay-btn-wrapper {
        margin: 0px -15px;
        position: fixed;
        bottom: 0;
        width: 100%;
    }
    .sq-creditcard {
       border-radius: 0px;
    }

    .sq-payment-form {
        margin-bottom: 50px;
    }
    .checkout-container {
        margin: 30px auto;
    }
    .form-user-details input::placeholder {
        font-size: 12px;
    }
    .pay-later-box {
        padding-bottom: 30px;
    }
    .user-permission-checkbox.checkbox label:before {
        position: absolute;
        left: 0px;
    }
    .user-permission-checkbox.checkbox label.radio-label, label.check-label {
        font-size: 14px;
    }
    .user-permission-checkbox.checkbox label.radio-label, label.check-label1 {
        padding-left: 30px;
    }
    
    .user-permission-checkbox.checkbox .checkbox input:checked + label:after
    {
        top:2px;
    }
}

@media(max-width:575px)
{
    .btn-group.tip-btn-group div {
        margin-left: 5px;
    }
    .payment-final h5,.order_box_wrapper h3 {
        font-size: 16px;
    }
    .qty-wrapper p,  p.color_b {
        font-size: 14px!important;
    }
    .form-user-details input {
        margin-bottom: 5px;
    }

  
}
@media(max-width:480px)
{
    .tip-btn-group .btn-primary {
        padding: 8px 6px;
    }
    .order_box_left_wrapper,.form-user-details {
        padding: 15px;
    }
    .form-user-details input::placeholder {
        font-size: 10px;
    }
}
@media(max-width:360px)
{
    .tip-btn-group .btn-primary {
        padding: 7px 5px;
        font-size: 11px;
    }
}
@media (min-width: 576px)
{
.custom-tip-modal .modal-dialog {
    max-width: 360px;
    margin: 1.75rem auto;
}
}