.form-wrapper .customer-checkbox .input-checkbox {
    width: auto;
    height: auto;
    margin-right: 10px;
    margin-bottom: 0;
    display: none;
    cursor: pointer;
}
.form-wrapper .customer-checkbox .input-checkbox-label {
    cursor: pointer;
    position: relative;
    font-size: 12px;
}

.input-checkbox-label.input-checkbox-label:before {
    border: 1px solid #ff914d !important;
    content: "";
    content: '';
    -webkit-appearance: none;
    padding: 10px;
    display: inline-block;
    position: relative;
    vertical-align: middle;
    cursor: pointer;
    margin-right: 4px;
    background-color: transparent;
    box-sizing: border-box;
}
.form-wrapper .customer-checkbox input:checked + label:after {
    content: '';
    display: block;
    position: absolute;
    top: 2px;
    left: 7px;
    width: 9px;
    height: 15px;
    border: solid #ff914d;
    border-width: 0 4px 4px 0;
    transform: rotate(45deg);
    box-sizing: border-box;
}
.font-20 {
    font-size: 20px;
}
.font-30 {
    font-size: 30px;
}