.single_msg_wrapper{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    overflow: hidden;
    text-align: center;
}
p.single_msg {
    /* background-color: #fafafa; */
    margin-bottom: 0px;
    padding: 0% 50px;
    /* box-shadow: 0px 0px 10px 45px rgb(0 0 0 / 5%); */
    /* -webkit-box-shadow: 0px 0px 10px 45px rgb(0 0 0 / 5%); */
}
.new-form-wrapper.add_cash_payment label {
    width: 100%;
    text-align: left;
}
.new-form-wrapper .form-title {
    margin-bottom: 15px;
}
.new-form-wrapper.add_cash_payment {
    width: 35%;
    margin: 0 auto;
}
.new-form-wrapper.add_cash_payment  h1.form-title {
    font-size: 30px;
}
.c_info {
    background-color: #fafafa;
    padding: 13px;
    width: 35%;
    margin: 15px auto;
}
.c_info p {
    margin-bottom: 5px;
    font-size: 15px;
}
.c_info h3 {
    font-size: 24px;
    padding-bottom: 5px;
    position: relative;
}
.c_info h3::after
{
    content: '';
    position: absolute;
    height: 4px;
    width: 84px;
    background-color: #f7904dde;
    left: 50%;
    transform: translateX(-50%);
    bottom: -4px;
}
@media(max-width:1199px)
{
    .new-form-wrapper.add_cash_payment,.c_info {
        width: 50%;
    }
}

@media(max-width:767px)
{
    .new-form-wrapper h1.form-title {
        font-size: 30px;
    }
    .new-form-wrapper.add_cash_payment,.c_info {
        width: 65%;
    }
}

@media(max-width:575px)
{
    .new-form-wrapper.add_cash_payment,.c_info {
        width: 90%;
    }
}