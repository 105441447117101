@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
.new-form-wrapper .form-wrapper {
    border: 1px solid #ececec;
    padding: 25px;
}
.invalid{
    border-color: #db3c36 !important;
}
.new-form-wrapper {
    /* width: 60%;
    position: absolute;
    top: 50%;
    bottom: 0px;
    left: 50%;
    transform: translate(-50%, -50%); */
}
.form-wrapper form {
    margin-bottom: 0;
}
h1.form-title {
    text-align: center;
    font-weight: 500;
}

/* .form-wrapper .form-group {
    margin-bottom: 15px;
} */

.form-wrapper .form-group input {
    width: 100%;
    border: 1px solid #dedede;
    height: 47px;
    padding-left: 15px;
    font-size: 16px;
    font-weight: 400;
    outline: none;
    box-shadow: none;
}

.form-wrapper button {
    border: 1px solid #dadada!important;
    height: 42px;
    width: 100%;
    text-transform: uppercase;
    box-shadow: none;
    cursor: pointer;
    background-color: black;
    color: white !important;
}

.submit-btn-wrapper {
    text-align: right;
}
.invalid-feedback{
    color: #db3c36;
    display: block !important;
    font-size: 15px !important;
}
.squre-form-wrapper {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
@media(max-width:767px)
{
    .squre-form-wrapper {
        position: relative;
        top: 15px;
        left: 0px;
        transform: inherit;
    }
}