.main-vendor-container {
  background: #fe8302;  /* fallback for old browsers */
  background: -webkit-linear-gradient(to right, #FFFFFF, #fe8302);  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to right, #FFFFFF, #fe8302); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  position: relative;
  display: flex;
  align-items: center;
  padding: 15px;
  cursor: pointer;
    border: 1px solid #eaeaea;
    border-radius: 10px;
    /* background: #dedede; */
    margin-bottom: 30px;
}
/* .main-vendor-container:hover {
  box-shadow: 0px 0px 30px rgba(0,0,0,0.3);
} */
.main-vendor-container .vendor-image-section {
  display: inline-block;
  width: 150px;
  height: 150px;
  position: relative;
}
.main-vendor-container .vendor-title {
  position: relative;
  top: 0;
  bottom: auto;
  margin: auto;
  left: 0;
  right: auto;
  transform: none;
  color: black;
  text-align: left;
  padding: 10px 5px;
  font-size: 24px;
}
.main-vendor-container .vendor-image-section .vendor-image {
  /* max-width: 100%; */
  height: 100%;
  width: 150px;
  object-fit: scale-down;
  /* position: absolute;
    left: 50%;
    max-width: 100%;
    max-height: 100%;
    top: 50%;
    transform: translate(-50%, -50%); */
}
.res-info {
  padding-left: 15px;
}
ul.res-tag {
  margin: 0;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
}
ul.res-tag li {
  list-style: none;
  width: auto;
  margin-left: 10px; 
  border: 1px solid #f8a500;
  padding: 0px 12px;
  line-height: 15px;
  background: #f8a500;
  color: #fff;
  font-size: 13px;
  border-radius: 15px;
  margin-bottom: 5px;
}
ul.res-tag li:first-child
{
  margin-left: 0px;
}
.res-info p {
  margin-bottom: 5px;
}
.row.resturant-list-row {
  margin-top: 80px;
}

@media(max-width:1199px)
{
  .resturant-list-row .col-md-3 {
    flex: 0 0 33.33%;
    max-width: 33.33%;
}
}


@media(max-width:768px)
{
  .resturant-list-row .col-md-3 {
    flex: 0 0 50%;
    max-width: 50%;
}
}
@media(max-width:575px)
{
  .resturant-list-row .col-md-3 {
    flex: 0 0 100%;
    max-width: 100%;
}
.resturant-list-row .col-md-3 .main-vendor-container {
  margin-bottom: 15px;;
}
.resturant-list-row .col-md-3:first-child .main-vendor-container
  {
    margin-top: 20px;
  }
  .row.resturant-list-row {
    margin-top: 50px;
}
}

@media (max-width: 1947px) and (min-width: 1550px) {
  .sidebar-trigger.sidebar-trigger-section .resturant-list-row .col-vendor{
      flex: 0 0 33.33%;
      max-width: 33.33%;
  }
}
@media (max-width: 1549px) and (min-width: 1200px) {
  .sidebar-trigger.sidebar-trigger-section .resturant-list-row .col-vendor{
      flex: 0 0 50%;
      max-width: 50%;
  }
}

@media (max-width: 1199px) and (min-width: 768px) {
  .sidebar-trigger.sidebar-trigger-section .resturant-list-row .col-vendor{
      flex: 0 0 100%;
      max-width: 100%;
  }
}