.cart_left_content {
    display: inline-block;
    float: left;
    width: 70%;
}
span.number {
    display: inline-block;
    padding-right: 10px;
    font-weight: 300;
    font-size: 14px;
    float: left;
}
span.title {
    font-weight: 300;
    font-size: 14px;
    float: left;
    padding-left: 12px;
}
span.special-instructions {
    font-size: 15px;
    display: block;
    padding-left: 60px;
}
span.variant {
    color: #b5b5b5;
}
.cart_right_content {
    display: flex;
    width: 30%;
    text-align: right;
    flex-direction: column;
}
span.item-img img {
    width: 50px;
    height: 50px;
    border-radius: 6px;
}
.cart_right_content .price {
    font-size: 16px;
    float: right;
    font-weight: 700;
    color: #ff914d;
    font-family: 'Roboto', sans-serif;
}
h4.tilte {
    display: inline-block;
    font-size: 18px;
}
.cart_right_content.subtotal_price {
    margin-top: 5px;
}
.cart_item_section {
    border-bottom: 1px solid #e2e2e2;
    padding: 10px;
    display: flex;
}
.cart_icon svg {
    width: 38px;
    margin-right: 20px;
}
.cart_icon a {
    position: relative;
    cursor: pointer;
}
.cart_icon .cart_count {
    position: absolute;
    font-size: 11px;
    background-color: #ff914d;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    text-align: center;
    top: -10px;
    right: 10px;
    color: #fff;
    line-height: 20px;

}
.cart-doc-main {
    height: 100vh;
    background: white;
    width: 400px;
    position: fixed;
    top: 54px;
    right: 0;
    z-index: 10;
    border-left: 1px solid #e6e8ec;
    border-top: 1px solid #e6e8ec;
}
.cart-doc-main-title {
    padding: 14px 12px;
    font-size: 18px;
    background: #dfe2eb;
}
.cart-doc-main-close{
    font-size: 15px;
    font-weight: 600;
    float: right;
    cursor: pointer;
    text-decoration: underline;
    cursor: pointer;
}
.navbar-custome {
    position: fixed !important;
    width: 100%;
    top: 0;
    z-index: 10;
}
.navbar-custome .navbar-text {
    padding: 0;
}
.cart_item_remove {
    border: none;
}
.remove-cart-icon {
    width: 15px;
    float: right;
    cursor: pointer;
    line-height: 1;
    margin-left: 20px;
}
.remove-cart-icon path {
    fill: #666666 !important;
}
.bg-white {
    background-color: #ffffff;
}
.order-not-avaible-text {
    padding: 0px 5px 0px 10px;
}
/*sidebar button*/
.sidebar-button {
    bottom: 0;
    position: fixed;
    border: 1px solid #ff914d;
    /* width: 350px; */
    /* margin: 0 auto; */
    background: #ff914d;
    /* color: #ffffff!important; */
    /* display: table; */
    width: 400px;
    cursor: pointer;
    text-align: center;
    padding: 12px;
}

.sidebar-button button.btn.btn-bottom {
    font-size: 18px;
    font-weight: 600;
    font-family: 'Roboto', sans-serif;
    letter-spacing: 0.4px;
}
.sidebar-button button {
    color: #fff!important;
    text-align: center;
    box-shadow: none !important;
}
.total-amount-cart {
    bottom: 86px;
    position: fixed;
    width: 380px;
    height: 25px;
    margin-left: 10px;
    display: flex;
    justify-content: space-between;
}
.total-amount-cart span:first-child {
    font-size: 18px;
    color: #000;
    font-weight: 400;
    font-family: 'Roboto', sans-serif;
}
.total-amount-cart span:last-child {
    font-weight: 700;
    color: #ff914d;
    font-size: 18px;
    font-family: 'Roboto', sans-serif;
}
.cart-doc-main-body {
    height: calc(100% - 180px);
    /* background: red; */
    overflow-y: auto;
    /* position: relative; */
}

.sidebar-trigger.sidebar-trigger-section {
    margin-right: 400px;
}
.common-primary-link {
    text-decoration: none;
    color: #000000;
    cursor: pointer;
    font-size: 16px;
    font-weight: 700;
    font-family: 'Roboto', sans-serif;
}
.pricewrapper {
    margin-bottom: 3px;
}
span.product-quantity {
    display: block;
    font-size: 14px;
    color: #a3a3a3;
    font-weight: 500;
}
/* .common-primary-link:hover {
    text-decoration: none;
    color: #0156b3;
    cursor: pointer;
} */

.bottom-cart-wrapper {
    display: flex;
    justify-content: space-between;
    width: 100%;
    position: fixed;
    bottom: 0;
}


@media (max-width: 768px) {
    .cart-doc-main {
        width: 100%;
        border-left: none;
    }
}
@media (max-width: 767px) {
    .cart-doc-main {
        width: 100%;
        border-left: none;
    }
    .back-btn button.btn.btn-bottom {
        outline: none;
        box-shadow: none !important;
    }
    .back-btn {
        width: 35%;
        /* float: left; */
        border: 1px solid #f8f9fa;
        text-align: center;
        line-height: 60px;
        background-color: #f8f9fa;
    }
    .sidebar-button {
        width: 70%;
        position: initial;
        padding-top: 10px;
        padding-bottom: 10px;
    }
    .cart-doc-main-body {
        height: calc(100% - 200px);
    }
    .total-amount-cart {
        bottom: 75px;
        margin-left: 0;
    width: 100%;
    padding: 0px 20px;
    }
}

.cart-back-icon svg {
    width: 15px;
}
.swal-button:focus {
    box-shadow: none;
}
.swal-button:not([disabled]):hover {
    background-color: #f7914d;
}
.swal-button.swal-button--confirm {
    background-color: #f7914d;
}
.swal2-styled.swal2-confirm {
    background-color: #ff914d !important;
}
.swal2-styled.swal2-cancel {
    border: 1px solid #f7914d !important;
    background-color: white !important;
    color: #f7914d !important;
}