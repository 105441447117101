.order-receive-container {
    margin-top: 30px !important;
    width: 50%;
    background: #f1f2f7;
    margin: 0 auto;
    padding: 50px;
    text-align: center;
   /* position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);*/
}
.order-receive-container img {
    width: 140px;
    border-radius: 50%;
    box-shadow: 0px 1px 3px 5px rgba(222,222,222,0.5);
    margin-bottom: 30px;
}
.ml-3 a {
    color: #f7914d!important;
    text-decoration: none;
}
/*thankyou page*/
.container.google-container {
    width: 50%;
    border-top: 2px solid #ff914d;
    border-bottom: 2px solid #ff914d;
    margin-top: 30px;
    padding: 25px 0px;
}
.google-qr-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.google-qr-btn.apple-btn,.google-qr-btn,.logo-center {
    width: 20%;
}
.google-qr-wrapper img {
    width: 100%;
    height: auto;
}
.google-qr-btn img:first-child {
    margin-right: 10px;
}
.go-to-menu-btn {
    padding: 10px 20px;
    border: 1px solid #ff914d;
    background-color: #ff914d;
    color: white;
    border-radius: 10px;
    font-size: 20px;
    cursor: pointer;
}
.go-to-menu-btn:focus {
    outline: none;
}
@media(max-width:1024px)
{
    .order-receive-container,.container.google-container {
        width: 65%;
    }
    .google-qr-btn.apple-btn,.google-qr-btn,.logo-center {
        width: 33.33%;
    }
    .google-qr-wrapper img {
        width: 100%;
        height: auto;
    }
}
@media(max-width:767px)
{
    .order-receive-container,.container.google-container {
        width: 90%;
    }
    .order-receive-container img {
        margin-bottom: 20px;
    }
    /* .logo-center
    {
        display:none;
    } */
    .google-qr-btn.apple-btn,.google-qr-btn{
        width: 30%;
    }
}
@media(max-width:575px)
{
    .order-receive-container {
        padding: 15px;
    }
    .order-receive-container h2 {
        font-size: 20px;
    }
    .order-receive-container h3 {
        font-size: 24px;
    }
    .go-to-menu-btn {
        padding: 8px 15px;
        font-size: 17px;
    }
    .order-receive-container {
        margin-top: 15px !important;
    }
    .ml-3 {
        margin-top: 8px;
    }
}