@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
.vendor-product-container {
    max-width: 1720px;
    margin: auto;
    padding: 0 15px;
}
div#navbarText {
    width: 100%;
    display: flex;
}
.vendor-product-container .vendor-product-section .category-title {
    text-align: left;
    font-size: 24px;
    color: #000;
    font-family: 'Roboto', sans-serif;
    font-weight: 700;
    margin: 20px 0px 10px 0px;
}

.vendor-product-container .product-category-content {
    display: flex;
    width: 100%;
    border: 1px solid #ada7a7;
    margin-bottom: 30px;
    border-radius: 5px;
    padding: 10px;
    align-items: center;
}

.vendor-product-container .product-category-content .product-category-img {
    display: inline-block;
    max-width: 50%;
    padding-right: 20px;
}

.vendor-product-container .product-category-content .product-category-img .category-image {
    max-width: 100%;
    width: auto;
}

.vendor-product-container .product-category-content .product-category-text {
    display: inline-block;
    width: 50%;
}
.product-category-menu {
    display: flex;
    align-items: center;
    /* width: 100px; */
    border: 1px solid;
    /* padding: 6px 15px; */
    border-radius: 6px;
    text-align: center;
    top: 2px;
    position: relative;
    margin-right: 10px;
    border: 1px solid #ff914d;
}
.product-category-menu .prod-cate-decrease {
    float: left;
    cursor: pointer;
}

.product-category-menu .prod-cate-increase {
    float: right;
    cursor: pointer;
}

.product-category-option {
    display: inline-block;
    padding-left:15px;
}
.product-category-menu span {
    padding: 10px 23px;
    font-size: 20px;
    font-family: 'Roboto', sans-serif;
}
.product-category-menu span:nth-child(2)
{
    background: #ffe9db;
}
.dynamic-price-section span.prod-cate-decrease,.dynamic-price-section span.prod-cate-increase {
    padding: 10px 17px;
    color: #ff914d;
    font-weight: 700;
    font-family: 'Roboto', sans-serif;
}
.dynamic-price-section div:last-child p {
    border: 1px solid #d4d5d9;
    padding: 14px 19px;
    border-radius: 6px;
    margin-left: 8px;
    /* margin-bottom: -5px; */
    /* margin-top: 5px; */
    font-size: 14px;
    font-family: 'Roboto', sans-serif;
    font-weight: 700;
    color: #000;
}
.modal.show {
    padding-right: 0 !important;
    padding-left: 0 !important;
}
.product-box-model button.btn-orange.btn.btn-orange {
    padding: 10px 25px;
    font-weight: 700;
    font-size: 18px;
    outline: none;
    box-shadow: none;
}
.add-to-cart-section {
    margin-top: 15px;
}
.add-to-cart-section button {
    background-color: #ffa500;
    border: 0;
    padding: 10px 30px;
    border-radius: 35px;
    font-size: 16px;
    cursor: pointer;
    outline: 0;
    color: #fff;
}
.product-category-option select {
    font-size: 15px;
    margin-left: 10px;
    padding: 5px 10px;
}
/* .product_title {
    width: 85%;
} */
.product_title h3 {
    font-size: 18px;
    color: #000;
    font-weight: 700;
    margin: 2px 0;  
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-family: 'Roboto', sans-serif;
  }
.product-box { 
    border-radius: 15px;
    margin-bottom: 30px;
    cursor: pointer;
    display: flex;
    justify-content: flex-start;
    background-color: #fff;    
    padding: 10px;
    -webkit-box-shadow: 5px 8px 10px 0px rgba(0,0,0,0.2);
    -moz-box-shadow: 5px 8px 10px 0px rgba(0,0,0,0.2);
    box-shadow:5px 8px 10px 0px rgba(0,0,0,0.2);
    font-family: 'Roboto', sans-serif;
}
.product_desc p {
    font-size: 12px;
    letter-spacing: 0.3px;
    font-weight: 400;
    color: #666666;
    margin-bottom: 0px;
    display: block;
    display: -webkit-box;   
    height: 43px;
    margin: 0 auto;   
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    line-height: normal;
}
.product_price p {
    font-size: 16px;
    font-weight: 700;
    margin-bottom: 0;
    color: #ff914d;
}

.product_modal.modal .modal-body h3.modal-product-title {
    font-size: 20px;
}
.product_modal .modal-body p {
    font-size: 14px;
    font-weight: 300;
    color: #8e8e8e;
}

.product_small_img img {
    width: 100px;
    height: 100px;
    object-fit: cover;    
    border-radius: 10px;
}
.product_details_right {
     width: 75%; 
    padding-left: 12px;
    padding-right: 5px;
}

/*header css*/
.vendor-res-img {
    width: 100%;
    height: 250px;
    background-color: #f3f3f3;
}
.vendor-res-img img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    margin: 0 auto;
    display: block;
}
.vendor-res-title h3 {
    font-size: 25px;
    color: #000000;
}
.vendor-res-desc {
    border-bottom: 1px solid #e2e2e2;
    padding: 20px;
    /* text-align: center; */
}
.vendor-res-info p {
    margin-bottom: 5px;
    font-size: 15px;
    color: #2d2d2d;
}
.spice-level select {
    width: 150px;
    height: 40px;
    border: 1px solid #ececec;
    padding-left: 8px;
    margin-left: 13px;
    outline: 0;
}
.product-addition-options {
    margin-top: 15px;
}
.product-addition-options .radio {
    width: 30%;
}
.product-addition-options .radio label {
    font-size: 13px;
    margin-bottom: 0;
}
/*radio-button css*/
.radio {
    margin: 0.5rem;
  }
  .radio input[type="radio"] {
    position: absolute;
    opacity: 0;
  }
  .radio input[type="radio"] + .radio-label:before {
    content: "";
    background: #f4f4f4;
    border-radius: 100%;
    border: 1px solid #b4b4b4;
    display: inline-block;
    width: 1.4em;
    height: 1.4em;
    position: relative;
    top: 0em;
    margin-right: 10px;
    vertical-align: top;
    cursor: pointer;
    text-align: center;
    -webkit-transition: all 250ms ease;
    transition: all 250ms ease;
  }
  .radio input[type="radio"]:checked + .radio-label:before {
    background-color: #ff914d;
    box-shadow: inset 0 0 0 4px #f4f4f4;
    border: 1px solid #ff914d;
  }
  .radio input[type="radio"]:focus + .radio-label:before {
    outline: none;
    border-color: #ff914d;
  }
  .radio input[type="radio"]:disabled + .radio-label:before {
    box-shadow: inset 0 0 0 4px #f4f4f4;
    border-color: #b4b4b4;
    background: #b4b4b4;
  }
  .radio input[type="radio"] + .radio-label:empty:before {
    margin-right: 0;
  }
  .product-addition-options{
    display: flex;
    flex-wrap: wrap;
    /* justify-content: space-between; */
    justify-content: flex-start;
}
.product-optional-items
{
    display: flex;
    flex-wrap: wrap;
    /* flex-direction: column; */
    /* justify-content: space-between; */
    justify-content: flex-start;
}
.product-addition-options h5,.product-optional-items h5 {
    width: 100%;
    font-weight: 700;
    font-size: 18px;
    font-family: 'Roboto', sans-serif;
    color: #000000;
}
  .product-optional-items .checkbox{
    /* width: auto; */
    width: 30%;
    margin-left: 0px;
}
.product-optional-items .checkbox label{
    font-size: 13px;
}
.radiodelivery {
    margin: 0.5rem 0px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .orderhr
  {
      margin: 5px 0px;
  }
  .radiodelivery input[type=radio] {
    position: absolute;
    opacity: 0;
    right: 15px;
  }
  .radiodelivery-label {
    font-weight: normal !important;
    width: 100%;
    position: relative;
  }
  .form-group.mb-0fg {
    margin-bottom: 0;
}
  .radiodelivery input[type=radio] + .radiodelivery-label:before {
    content: "";
    background: #ffffff;
    border-radius: 100%;
    border: 1px solid #b4b4b4;
    display: inline-block;
    width: 22px;
    height: 22px;
    position: absolute;
    top: 2px;
    right: 0;
    margin-right: 1em;
    vertical-align: top;
    cursor: pointer;
    text-align: center;
    transition: all 250ms ease;
  }
  .radiodelivery input[type=radio]:checked + .radiodelivery-label:before {
    background-color: #f7914d!important;
    box-shadow: inset 0 0 0 4px #f4f4f4;
  }
  .radiodelivery input[type=radio]:focus + .radiodelivery-label:before {
    outline: none;
    border-color: #f7914d!important;
  }
  .radiodelivery input[type=radio] + .radiodelivery-label:empty:before {
    margin-right: 0;
  }
.custome-modal-footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    border-top: 1px solid #ececec;
    padding-top: 15px;
    flex-wrap: wrap;
}
.modal-footer-main.modal-footer {
    border-top: 0;
    padding-top: 0;
}
.modal-body-main.modal-body {
    border-top: 1px solid #ececec;
    padding: 15px 0;
    width: 95%;
    margin: 0 auto;
}
.modal-header-main.modal-header {
    border-bottom: 0;
    /* padding-bottom: 0; */
}
/*checkbox design*/
.checkbox {
    display: block;
    margin-bottom: 15px;
  }
  
  .checkbox input {
    padding: 0;
    height: initial;
    width: initial;
    margin-bottom: 0;
    display: none;
    cursor: pointer;
  }
  
  .checkbox label,.checkbox label {
    position: relative;
    cursor: pointer;
    font-size: 15px;
  }
  
  .checkbox label:before {
    content:'';
    -webkit-appearance: none;
    background-color: transparent;
    border: 1px solid #e2e2e2;
    /* box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05), inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05); */
    padding: 10px;
    display: inline-block;
    position: relative;
    vertical-align: middle;
    cursor: pointer;
    margin-right: 4px;
  }
  
  .checkbox input:checked + label:after {
    content: '';
    display: block;
    position: absolute;
    top: 4px;
    left: 7px;
    width: 9px;
    height: 15px;
    border: solid #ff914d;
    border-width: 0 4px 4px 0;
    transform: rotate(45deg);
  }
  .checkbox input:checked + label:before {
    border: 1px solid #ff914d !important;
}
.product-special-instructions input#specialInstructions {
    height: 44px;
    border-radius: 8px;
    border: 1px solid #cecece;
}

  .product-optional-items h5 {
    margin-bottom: 15px;
}
.product-optional-items p {
    margin-bottom: 5px;
    margin-top: -15px;
    width: 100%;
    font-weight: 700;
    font-family: 'Roboto', sans-serif;
}
.product-optional-items {
    margin-top: 0px;
}
.p-t-61 {
    padding-top: 61px;
}
.special-name input {
    width: 100%;
    padding-left: 12px;
    font-size: 14px;
    height: 39px;
    outline: 0;
    border: 1px solid #ececec;
}
.special-name {
    width: 100%;
}
.model-product-image {
    /* width: 465px; */
    width: 100%;
    height: 355px;
    object-fit: cover;
    border: 1px solid #ececec;
    padding: 2px;
    border-radius: 4px;
    margin: 0 auto;
    display: block;
    margin-bottom: 10px;
}

.vendor-header .row {
    align-items: center;
}
.vendor-header {
    overflow: hidden;
    background: #f0f1f5;
}

.sidebar-cart_title-wrapper {
    display: flex;
}

.center-box-cn {
    display: table;
    height: 100vh;
    width: 100%;
}
.center-box-cn .center-box-section {
    display: table-cell;
    height: 100%;
    vertical-align: middle;
    width: 100%;
}
.product-upsell-items h5 {
    font-size: 18px;
    font-weight: 600;
}
.product_modal .modal-footer{
    justify-content: space-between !important;
}
.product-box-model h3.modal-product-title {
    font-size: 24px;
    font-weight: 700;
    color: #000;
}
.fade.product_modal.product-box-model .modal-title h5 {
    font-size: 18px;
    font-weight: 700;
    color: #ff914d;
    font-family: 'Roboto', sans-serif;
}
.product-box-model .modal-title p {
    color: #666666;
    font-size: 14px;
    font-family: 'Roboto', sans-serif;
    margin-bottom: 0;
}
.btn-orange {
    color: #fff !important;
    background-color: #ff914d !important;
    border-color: #ff914d !important;
    border: 1px solid;
}

.btn-orange:hover {
    color: #fff;
    background-color: #ff914d;
    border-color: #ff914d;
}

.btn-orange:focus {
    color: #fff !important;
    background-color: #ff914d !important;
    border-color: #ff914d !important;
    outline: 0;
}
.btn-orange:active {
    color: #fff;
    background-color: #ff914d;
    border-color: #ff914d;
}

.res-search-wrapper {
    width: 60%;
    text-align: center;
    display: inline;
    margin-left: 20px;
    position: relative;
    /* padding: 12px 0px 0px; */
}
.res-search-wrapper img {
    position: absolute;
    right: 15px;
    top: 15px;
}
.res-search-wrapper input {
    width: 100%;
    height: 50px;
    border: 1px solid #f0f1f5!important;
    padding-left: 20px;
    background: #f0f1f5;
    outline: none!important;
    box-shadow: none!important;
    border-radius: 10px;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}
input#search::placeholder {
    color: #777676;
}


.delivery-content .css-g1d714-ValueContainer {
    height: 42px;
}
.delivery-content .css-1wa3eu0-placeholder {
    transform: translateY(-34%);
    box-sizing: border-box;
    top: 37%;
}
.delivery-content .css-1lovj44-Input {
    margin: -10px 2px;
}

.dynamic-price-section {
    display: flex;
    align-items: center;
    width: 50%;
}
body.modal-open {
    padding-right: 0px!important;
}

@media(max-width:1199px){
    .vendor-product-container .product-category-content .product-category-text h4 {
        font-size: 20px;
    }
    .vendor-product-section .col-md-3.catagory-col {
        flex: 0 0 33.33%;
        max-width: 33.33%;
    }
    /* .address-place {
        max-width: 25% !important;
    } */
    .sidebar-trigger-section .search-section .res-search-wrapper {
        max-width: 100% !important;
    }
    .search-section .res-search-wrapper {
        max-width: 68% !important;
    }
    .sidebar-trigger-section .address-place {
        max-width: 45%;
    }
    .sidebar-trigger-section .display-contents .order-button-group {
        width: 100% !important;
    }
    
    
    /* .display-contents .order-button-group {
        width: 45% !important;
    } */
    .restaurent-wrapper {
        padding: 10px 20px !important;
    }
}
@media(max-width:1024px){
    .vendor-product-container .product-category-content .product-category-text p{
        font-size: 14px;
    }
}

@media(max-width:991px)
{
    .sidebar-trigger-section .display-contents {
        flex-direction: column;
    }
    .sidebar-trigger-section .address-place {
        max-width: 100%!important;
    }
    .sidebar-trigger-section .search-section .res-search-wrapper {
        margin-top: 10px!important;
    }
    .sidebar-trigger-section .address-place {
        max-width: 100%!important;
        margin-top: 10px;
    }
    .sidebar-trigger-section .delivery-active .order-button-group {
        justify-content: center;
    }
    .sidebar-trigger-section .search-section .order-btn-primary:last-child {
        margin-right: 0px!important;
    }
    .sidebar-trigger-section .display-contents .order-button-group {
        width: 100% !important;
        justify-content: space-between;
        display: flex;
        text-align: center;
    }
    .sidebar-trigger-section  .search-section .order-btn-primary {
        padding: 12px 20px;
    }
}

@media(max-width:768px) and (min-width:768px)
{
    .vendor-header .row .col-md-8
    {
        flex: 0 0 60%;
        max-width: 60%;
    }
    .vendor-header .row .col-md-4
    {
        flex: 0 0 40%;
        max-width: 40%;
    }
    .delivery-active2 {
        margin-top: 15px;
    }
   
    .without-delivery .display-contents .order-button-group {
        width: 28%!important;
    }
    .search-section.without-delivery  .res-search-wrapper{margin-top: 0px;}
}

@media(max-width:768px){
    .vendor-product-section .col-md-3.catagory-col {
        flex: 0 0 50%;
        max-width: 50%;
    }
}

@media(max-width:767px){
    .product-optional-items .checkbox {
        width: 50%;
        margin-left: 0px;
    }
    .vendor-product-container .product-category-content .product-category-img{max-width:120px;}
    .vendor-product-container .product-category-content .product-category-text {
        display: inline-block;
        width: calc(100% - 120px);
    }
    /* .product-category-menu{
        padding: 3px 15px;
        font-size:14px;
    } */
    .dynamic-price-section {
        width: 60%;
    }
    .add-to-cart-section {
        margin-top: 10px;
    }
    .vendor-product-container .product-category-content .product-category-text p{
        font-size: 12px;
    }
    .add-to-cart-section button{
        padding: 4px 15px;
        font-size: 14px;
    }
    .vendor-product-container .product-category-content .product-category-text h4 {
        font-size: 18px;
    }
    .product-category-option label {
        font-size: 13px;
    }
    .product-category-option select {
        font-size: 12px;
        margin-left: 8px;
        padding: 5px 5px;
    }
    .vendor-product-container .vendor-product-section .category-title{
        font-size:26px;
    }
    .vendor-res-img {
        height: 230px;
    }
    label.radio-label, label.check-label {
        font-size: 14px;
    }
}

@media(max-width:680opx)
{
    .cate-search-select,.delivery-active2 {
        width: 100%!important;
    }

}


@media(max-width:575px){
    .vendor-product-section .col-md-3.catagory-col {
        flex: 0 0 100%;
        max-width: 100%;
    }
    .vendor-product-container .vendor-product-section .category-title {
        text-align: left;
        font-size: 20px;
        margin: 5px 0px 10px 0px;
    }
    .res-search-wrapper input {
        width: 80%;
    }
}

@media(max-width:479px){
    .vendor-product-container .product-category-content .product-category-img{
        width:100%;
        max-width:100%;
        padding-right:0;
    }
    .vendor-product-container .product-category-content .product-category-text{
        width:100%;
        margin-top:10px;
    }
    .vendor-product-container .product-category-content{
        display: block;
    }
    
    /* .model-product-image {
        width: 310px;
        height: 250px;
    } */
    .dynamic-price-section {
        width: 100%;
        justify-content: space-between;
    }
    .model-button-wrapper {
        width: 100%;
        text-align: center;
    }
    .model-button-wrapper button.btn-orange.btn.btn-orange {
        width: 100%;
        margin-top: 8px;
    }
}
.vendor-product-breadcrumb {
    padding-top: 61px !important;
}
@media (max-width: 1947px) and (min-width: 1550px) {
    .sidebar-trigger.sidebar-trigger-section .vendor-product-container .vendor-product-section .catagory-col{
        flex: 0 0 33.33%;
        max-width: 33.33%;
    }
}

@media(max-width:1679px)
{
    .sidebar-trigger-section .search-section {
        flex-direction: column;
    }
    .sidebar-trigger-section  .display-contents {
        display: flex;
        width: 100%;
        justify-content: space-between;
    }
    .sidebar-trigger-section .search-section .res-search-wrapper {
        max-width: 100%;
        margin-top: 20px;
    }
    .sidebar-trigger-section .search-section-delivery .res-search-wrapper {
        max-width: 100%;
        margin-top: 0px;
    }
    .search-section .res-search-wrapper {
        width: 94% !important;
    }
    .sidebar-trigger-section .address-place {
        /* max-width: 50%; */
        max-width: 47%;
    }
    
    
   
}


@media (max-width: 1549px) and (min-width: 1200px) {
    .sidebar-trigger.sidebar-trigger-section .vendor-product-container .vendor-product-section .catagory-col{
        flex: 0 0 50%;
        max-width: 50%;
    }
}

@media (max-width: 1199px) and (min-width: 768px) {
    .sidebar-trigger.sidebar-trigger-section .vendor-product-container .vendor-product-section .catagory-col{
        flex: 0 0 100%;
        max-width: 100%;
    }
}



.overlayer{
    position:absolute;
    height: 100%;
    width: 100%;
    z-index: 9999;
    background-color: rgb(255 255 255 / 50%);

}
.search-section {
    padding: 20px;
    width: 100%;
    max-width: 1290px;
    margin: 0 auto;
    display: flex;
    font-family: 'Roboto', sans-serif;
    justify-content: center;
}
.search-section.search-section-delivery {
    flex-direction: unset;
}


.css-2613qy-menu{top:15px}
.address-place
{
    width: 100%;
    /* max-width: 268px; */
    max-width: 225px;
    margin-right: 10px;
}
.search-section .css-1wa3eu0-placeholder,.search-section .css-tlfecz-indicatorContainer {
    color: #000000;
}
.search-section .css-1okebmr-indicatorSeparator{
background-color: #000;
}
.search-section .res-search-wrapper input {
    color: #737373;
}
.css-yk16xz-control,.css-1pahdxg-control {
    padding: 5px;
}
.order-btn-primary {
    background: white;
    padding: 10px 10px;
    border: 1px solid #f7914d;
    color: #f7914d;
    border-radius: 5px;
    font-weight: 600;
    cursor: pointer;
}
.order-btn-primary:focus {
    outline: none;
}

.order-btn-primary.active {
    background: #f7914d;
    color: #ffffff;
}

.select-schedule .btn-group{
    width: 100%;
}
.select-schedule .btn-group .btn-primary{
    background-color: #ffffff;
    border-color: #f7914d;
    color: #f7914d;
    font-weight: 600;
}
.select-schedule .btn-group .btn-primary.active{
    background-color: #f7914d !important;
    color: #ffffff !important;
    border-color: #f7914d !important;
}
.select-schedule .btn-group .btn-primary:active{
    background-color: #f7914d !important;
    color: #ffffff !important;
    border-color: #f7914d !important;
}
.select-schedule .btn-group .btn-primary:focus{
    box-shadow: none !important;
}
.select-schedule .btn-group .btn-primary.active:focus{
    box-shadow: none !important;
}
.delivery-content label {
    display: block;
    font-size: 16px;
    font-weight: 500;
}
.delivery-content input[type=text], .delivery-content select {
    width: 100%;
    height: 48px;
    font-size: 14px;
    padding-left: 10px;
    border: 1px solid #ececec;
    color: #000;
    border-radius: 6px;
}
.time-date select {
    width: 48%;
}

.time-date {
    display: flex;
    width: 100%;
    justify-content: space-between;
    margin-bottom: 15px;
}
.schedule_model .modal-body {
    padding-bottom: 0px;
}
.delivery-content {
    padding-top: 25px;
}
label.light-label {
    font-weight: 300;
    font-size: 16px;
}
.or-div {
    text-align: center;
    position: relative;
    padding-bottom: 8px;
}
.or-div span {
    display: block;
    height: 25px;
    width: 25px;
    margin: 0 auto;
    background: #fff;
    border-radius: 25px;
    z-index: 11;
}
.or-div::after,.or-div::before
{
    content: '';
    position: absolute;
    height: 1px;
    width: 45%;
    z-index: 0;
    background-color: #e7e7e7;
    top: 10px;
}
.or-div::after
{
    right: 0;
}
.or-div::before
{
    left: 0;
}

  .location-div {
    display: inline-block;
    width: 20%;
}
  .location-div input
  {
      display: inline;
    width: 94%;
    height: 50px;
    border: 1px solid #ddd!important;
    padding-left: 20px;
    background: #ffffff;
    outline: none!important;
    box-shadow: none!important;
    border-radius: 10px;
  }
 
.display-contents {
    display: contents;
}
  /* .delivery-content .css-yk16xz-control,.delivery-content .css-1pahdxg-control{height: 50px;} */
  /* .delivery-content .css-g1d714-ValueContainer{height: 40px;} */

/* btn 
background-color: #007bff;
    border-color: #007bff; */

/** 06/01/2020 css start riddhi **/
.sidebar-trigger {
    background-color: #f0f1f5;
}
.product_details_bottom small {
    font-size: 11px;
    display: block;
    text-align: center;
    letter-spacing: 0.4px;
    margin-bottom: 5px;
}
span.product-count{
    display: table;
    background-color: #ff914d;
    color: #fff;
    font-weight: 500;
    border-radius: 6px;
    padding: 1.5px 7px 1.5px 8px;
    margin-left: auto;
    font-size: 14px;
}
.add-btn-product{
    border: 1px solid #ff914d;
    color: #000;
    font-size: 14px;
    background-color: transparent;
    border-radius: 2px;
    width: 85px;
    padding: 3.5px 6px; 
    margin: 6px 0 2px;
}
.main-search-section {
    background-color: #fff;
    border-top: 1px solid #c1c2c6;
}
.main-tab-wrapper .nav-tabs {
    border-bottom: 0;
    max-width: 1720px;
    margin: auto;
    padding: 0 8px;
}

.nav-tabs .nav-link{
color: #000;
background-color: #fff; 
border-radius: 4px;
margin: 0 6px;
border: 0;
font-size: 16px;
font-weight: 500;
font-family: 'Roboto', sans-serif;
}
.main-tab-wrapper .nav-tabs .nav-item.show .nav-link,.main-tab-wrapper .nav-tabs .nav-link.active{
    border: 0;
    background: #f7914d;
    color: #ffffff;
}
.search-section .order-btn-primary {
    font-weight: 500;
    padding: 12px 14px;
    margin-right: 10px!important;
}
.search-section .res-search-wrapper{
    margin-left: 0;
max-width: 674px;
width:100%;
}
.search-section .delivery-active1 {
    /* width:62% !important; */
    width:42% !important;
}
.delivery-active .order-button-group {
    /* width: 45%; */
}
.select-box-cate {
    display: inline;
}
.restaurent-wrapper {
    display: flex;
    max-width: 1255px;
    /* max-width: 1720px; */
    margin: 0 auto;
    align-items: center;
    padding: 10px 0px;
}
.res-logo img {
    width: 260px;
    height:auto;
}
.res-deatil h4 {
    margin-bottom: 0px;
    color: #242424;
    font-family: 'Roboto', sans-serif;
    font-size: 25px;
    }
    .res-deatil a {
    color: #f7914d !important;
    text-decoration: underline !important;
    cursor: pointer;
    }
    .res-deatil p {
        margin-bottom: 5px;
    }
    .res-search-wrapper.search-res-main {
        /* margin: 0 auto; */
        margin-top: 0 !important;
    }
.restaurent-wrapper .res-deatil {
    padding-left: 15px;
}
.row.header-row {
    border-bottom: 1px solid #cecfd3;
}
.color-red {
    color: red !important;
}
.m-t--5-i {
    margin-top: -5px !important;
}

/*22222*/
.modal-plus-minus-btn-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 15px;

}
.modal-plus-minus-btn {
    display: flex;
    align-items: center;
}
.modal-plus-minus-btn .subrow-price {
    margin-right: 15px;
    font-size: 13px;
}
.modal-plus-minus-btn .product-category-menu {
    border-radius: 0px;
}
.modal-plus-minus-btn .product-category-menu span,
.modal-plus-minus-btn .dynamic-price-section span.prod-cate-decrease,.modal-plus-minus-btn .dynamic-price-section span.prod-cate-increase
{
    padding: 3px 10px;
}
.modal-plus-minus-title span {
    font-size: 13px;
}
.modal-plus-minus-btn .product-category-menu span:nth-child(2) {
    background: #ffffff;
    font-size: 14px;
    padding: 3px 0px;
    width: 30px;
}

.cate-search-select {
    height: 50px;
    padding: 0 15px;
    margin-right: 10px;
    border: 1px solid #f7914d;
    border-radius: 5px;
    outline: none;
    width: 180px;
    text-overflow: ellipsis;
    word-wrap: unset;
}
.child-topping-box {
    width: 100%;
}
.display-contents {
    display: contents;
}
.radio_fancy input {
    opacity: 0;
}
.radio_fancy .radio_fancy_option {
    background: #ffffff;
    display: inline-block;
    /* margin: 0 10px; */
    width: 30px;
    height: 30px;
    border-radius: 50%;
}
.radio_fancy .radio_fancy_option input {
    width: 30px;
    height: 30px;
    vertical-align: top;
}

.radio_fancy .radio_fancy_option:last-child:before {
    content: '';
    width: 30px;
    height: 30px;
    background: #dadada;
    display: inline-block;
    position: absolute;
    border-radius: 50%;
    border: 2px solid #dadada;
    box-shadow: inset 0 0 0 3px #ffffff;
}
.radio_fancy .radio_fancy_option.check-topping-radio:last-child:before {
    background: #e49028;
    border: 2px solid #f7914d;
    box-shadow: inset 0 0 0 3px #ffffff;
}
.font-weight-300 {
    font-weight: 300;
}
.m-b-5 {
    margin-bottom: 5px;
}
.font-weight-700 {
    font-weight: 700;
}
/* .radio_fancy .radio_fancy_option.check-topping-radio:nth-child(2):after,.radio_fancy .radio_fancy_option.check-topping-radio:first-child:after {
    content: '';
    width: 30px;
    height: 30px;
    position: absolute;
    display: inline-block;
    border-radius: 50%;
    left: 0;
    border: 2px solid #f7914d;
    box-shadow: inset 0 0 0 3px #ffffff;
} */
/* .radio_fancy .radio_fancy_option:first-child:after,.radio_fancy .radio_fancy_option:nth-child(2):after
{
    content: '';
    width: 30px;
    height: 30px;
    position: absolute;
    display: inline-block;
    border-radius: 50%;
    left: 0;
    border: 2px solid #dadada;
    box-shadow: inset 0 0 0 3px #ffffff;
} */
.radio_fancy .radio_fancy_option:first-child:before {
    content: '';
    width: 30px;
    height: 30px;
    background: #ffffff;
    display: inline-block;
    position: absolute;
    border-radius: 50%;
    border: 2px solid #dadada;
    border-left: 15px solid #dadada;
}
.radio_fancy .radio_fancy_option.check-topping-radio:first-child:before {
    background: #ffffff;
    border: 2px solid #f7914d;
    border-left: 15px solid #f7914d;
}
.radio_fancy .radio_fancy_option:nth-child(2):before {
    content: '';
    width: 30px;
    height: 30px;
    background: #ffffff;
    display: inline-block;
    position: absolute;
    border-radius: 50%;
    border: 2px solid #dadada;
    border-right: 15px solid #dadada;
}
.radio_fancy .radio_fancy_option.check-topping-radio:nth-child(2):before
{
    background: #ffffff;
    border: 2px solid #f7914d;
    border-right: 15px solid #f7914d;
}
span.sub_topping_title span {
    font-size: 14px;
    color: #313438;
}

h5.childOptionTopping_name {
    display: flex;
}
.child-topping-box .childOptionTopping_name span.sub_topping_title_left {
    font-size: 13px;
    margin-top: 10px;
}
span.sub_topping_title_left {
    width: 40%;
}
.radio_fancy_wrapper,.sub_topping_title
{
    width:60%;
    display: flex;
}
.radio_fancy {
    width: 100%;
    display: flex;
}
span.sub_topping_title span,.radio_fancy_option {
    width: 33.33% !important;
    position: relative;
}
h5.childOptionTopping_name.toppingName_main {
    margin-bottom: 15px;
}
.check-lable-font-13 {
    font-size: 13px !important;
}
.width-100 {
    width: 100%;
}
@media (min-width: 768px){
    .product-box-model .modal-dialog {
        max-width: 692px;
        margin: 1.75rem auto;
    }
}
@media (max-width: 1599px){
    .main-tab-wrapper .col-md-3 {flex: 0 0 33.33%;max-width: 33.33%;}
}
@media (max-width: 420px){
    .product-optional-items .checkbox, .product-addition-options .radio {
    
        width: 100%;
    
    }
}
@media (max-width: 1199px){
    .product_small_img img {width: 70px;height: 70px;}
    .product_title h3{font-size: 14px;}
    .product_price p{font-size: 12px;}
    .add-btn-product{width: 70px;margin-top: 0;}
    .product_details_right {
        /* width: 52%; */
        padding-left: 10px;
    }
    .res-logo img {
        width: 290px;
        height:auto;
    }
}
@media (max-width: 991px){
    .vendor-product-section .col-md-3.catagory-col {flex: 0 0 50%;max-width: 50%;}
    .search-section {
        flex-wrap: wrap;
        justify-content: center;
    }
    .search-section .res-search-wrapper {
        max-width: 100% !important;
        margin-top: 15px;
    }
    .address-place {
        max-width: 40% !important;
    }
    .display-contents .order-button-group {
        width: 50% !important;
    }
    .address-place {
        max-width: 100% !important;
        margin-right: 0px;
        margin-top: 20px;
    }
    .cate-search-select-main {
        width: 100%;
        margin-bottom: 20px;
    }
    .cate-search-select-main .cate-search-select {
        width: 100%;
    }
}
@media (max-width: 767px){
    .time-date select {
        font-size: 13px;
    }
    .vendor-product-section .col-md-3.catagory-col {flex: 0 0 100%;max-width: 100%;}
    /* .product_details_right { width: 73%;} */
    .display-contents .order-button-group {
        width: 100% !important;
        justify-content: center;
        text-align: center;
    }
    .search-section .order-btn-primary:last-child {
        margin-right: 0px!important;
    }
    .res-search-wrapper input {
        width: 100%!important;
    }
    .address-place {
        max-width: 100% !important;
        margin-top: 10px;
    }
    .cate-search-select {
        margin-top: 15px;
        width:100%;
    }
    .search-section .res-search-wrapper,.cate-box.cate-box{
        max-width: 100%!important;
        width: 100%!important;
    }
    .restaurent-wrapper {
        display: block;
    }
    .res-logo {
        text-align: center;
    }
    .res-deatil {
        padding-top: 20px;
    }
    span.sub_topping_title span{
        font-size: 12px!important;
    }
    .radio_fancy_wrapper, .sub_topping_title {
        width: 50%;
    }
    span.sub_topping_title_left {
        width: 50%;
    }
    .product-addition-options .radio {
        width: 50%;
        margin-left: 0;
        margin-right: 0;
    }
}
@media (max-width: 480px){
.product_small_img img {width: 60px;height: 60px;}
/* .product_details_right {width: 57%;} */
}
@media(max-width:1278px){
    .delivery-active .order-button-group {width: auto;display: flex;}
    /* .address-place {max-width: 20%;} */
    .search-section .res-search-wrapper {max-width: 55%;}
    }

@media(max-width:1271px)
{
    .search-section .res-search-wrapper {max-width: 42%!important;}
    .address-place {
        max-width: 240px;
    }

}
@media(max-width:676px)
{
    .search-section .res-search-wrapper {
        max-width: 100%!important;
    }
}
    
    @media(max-width:360px) {
        .search-section .order-btn-primary {
            padding: 12px 12px;
        }
        /* .product_details_right {
            width: 52%;
        } */
    }
    @media(max-width:420px) {
        .product-addition-options .radio {
            width: 100%;
            margin-left: 0;
            margin-right: 0;
        }
        /* .product_details_right {
            width: 52%;
        } */
    }

    @media (min-width: 1280px) and (max-width: 1280px) {
        .sidebar-trigger-section .address-place {
            /* max-width: 50%; */
            max-width: 41%;
        }
        .search-section .res-search-wrapper {
            width: 42% !important;
        }
    }
    @media (min-width: 1024px) and (max-width: 1024px) {
        .sidebar-trigger-section .display-contents {
            display: unset;
            width: 100%;
            justify-content: space-between;
        }
        .sidebar-trigger-section .address-place {
            max-width: 81%;
            margin-top: 20px
        }
        .search-section .delivery-active1 {
            width: 81% !important;
        }
    }
/** 06/01/2020 css end riddhi **/
