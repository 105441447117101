.radio-wrapper {
  width: 100%;
  height: 100vh;
  overflow: hidden;
  position: relative;
  background-color: #f1f2f7;
}
.radio-wrapper form {
  border: 1px solid #ececec;
  width: auto;
  padding: 25px 50px;
  height: auto;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background: #fff;
  border-radius: 10px;
}
.radio-wrapper .radiodelivery {
  margin: .5rem 0;
  display: block;
  justify-content: space-between;
  align-items: center;
      margin-bottom: 20px;
}
.radio-wrapper .radiodelivery input[type=radio] {
  position: absolute;
  opacity: 0;
  right: 15px;
}
.radio-wrapper .delivery-content label {
  display: block;
  font-weight: 500;

}
.radio-wrapper .radiodelivery-label {
  font-weight: 400!important;
  width: 100%;
  position: relative;
  padding-left: 35px;
    font-size: 18px;
}
.radio-wrapper .radiodelivery input[type=radio]:checked+.radiodelivery-label:before {
  background-color: #9e9d9d!important;
  box-shadow: inset 0 0 0 4px #f4f4f4;
  border: 1px solid #ececec!important;
}
.radio-wrapper .radiodelivery input[type=radio]+.radiodelivery-label:before {
    content: "";
  background: #fff;
  border-radius: 100%;
  border: 1px solid #b4b4b4;
  display: inline-block;
  width: 22px;
  height: 22px;
  position: absolute;
  top: 4px;
  left: 0;
  margin-right: 1em;
  vertical-align: top;
  cursor: pointer;
  text-align: center;
  transition: all .25s ease;
}
.radio-wrapper .submit-btn-wrapper button {
  padding: 10px 24px;
  font-size: 16px;
  border: 1px solid #5d5d5d;
  background-color: #5d5d5d;
  color: #fff;
  border-radius: 4px;
  outline: none;
  cursor: pointer;
}
.radio-wrapper a.botttom-link {
  display: inline-block;
  margin-top: 15px;
  color: #ff914d;
  text-decoration: none;
}
textarea.other-text {
  width: 100%;
  border: 1px solid #ececec;
  resize: none;
  margin-bottom: 12px;
  border-radius: 5px;
  outline: none;
}
.success-msg {
  text-align: center;
  background: #f1f2f7;
  padding: 15px;
}
.success-msg img {
  width: 140px;
}
@media(max-width: 767px){
  .radio-wrapper form {width: 85%;}
}